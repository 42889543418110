import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { LocaleService } from 'src/app/services/locale.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
})
export class NavHeaderComponent {
  appName = 'Product Browser';
  @Output() toggleDrawer = new EventEmitter();
  @Input() forbidden: boolean | undefined;

  constructor(
    public auth: AuthService,
    public locales: LocaleService,
  ) {}

  getLocaleUrl(): string {
    return `${this.locales
      .getCurrentLocale()
      .language.toLowerCase()}/${this.locales
      .getCurrentLocale()
      .country.toLowerCase()}`;
  }

  clickToggleDrawer() {
    this.toggleDrawer.emit();
  }
}
