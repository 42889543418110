<app-shared-header
  [headerData]="packageType?.headerData"
  [loading]="loading"
></app-shared-header>
<div *ngIf="!loading; else skeleton" fxLayout="column" fxLayoutGap="16px">
  <app-shared-list [entries]="packageType?.packages"></app-shared-list>
</div>
<ng-template #skeleton>
  <ngx-skeleton-loader
    count="5"
    [theme]="{ height: '50px', width: '100%' }"
  ></ngx-skeleton-loader>
</ng-template>
