<mat-toolbar color="primary" class="mat-elevation-z0">
  <mat-toolbar-row
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="6px"
  >
    <div fxLayout="row">
      <button mat-icon-button (click)="clickToggleDrawer()" *ngIf="!forbidden">
        <mat-icon class="color-white">menu</mat-icon>
      </button>
      <a
        fxLayoutAlign="start center"
        [routerLink]="getLocaleUrl()"
        class="clickable"
      >
        <img
          fxHide.lt-sm="true"
          src="../../../assets/img/viessmann-white.svg"
          alt="Viessmann Logo"
        />
        <h3 fxLayoutAlign="start start">{{ appName }}</h3>
      </a>
    </div>
    <app-search-bar
      fxShow
      fxHide.lt-sm
      fxFlex="0 1 600px"
      *ngIf="!forbidden"
    ></app-search-bar>
    <div fxLayout="row">
      <vih-login></vih-login>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row fxHide fxShow.lt-sm>
    <app-search-bar fxFlex="100"></app-search-bar>
  </mat-toolbar-row>
</mat-toolbar>
