import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  env: 'local',
  apiUrl: 'https://api-staging.viessmann.com/',
  app: {
    defaultLocale: {
      language: 'en',
      country: 'DE',
      salesOrg: 'DE',
      flag: 'de',
    },
    supportedLocales: [
      { language: 'de', country: 'Master', salesOrg: '', flag: 'world' },
      { language: 'de', country: 'DE', salesOrg: 'DE', flag: 'de' },
      { language: 'en', country: 'DE', salesOrg: 'DE', flag: 'de' },
      { language: 'de', country: 'AT', salesOrg: 'AT', flag: 'at' },
      { language: 'de', country: 'CH', salesOrg: 'CH', flag: 'ch' },
      { language: 'fr', country: 'CH', salesOrg: 'CH', flag: 'ch' },
      { language: 'it', country: 'CH', salesOrg: 'CH', flag: 'ch' },
      { language: 'da', country: 'DK', salesOrg: 'DK', flag: 'dk' },
      { language: 'pl', country: 'PL', salesOrg: 'PL', flag: 'pl' },
      { language: 'fr', country: 'FR', salesOrg: 'FR', flag: 'fr' },
      { language: 'nl', country: 'NL', salesOrg: 'NL', flag: 'nl' },
      { language: 'fr', country: 'BE', salesOrg: 'BE', flag: 'be' },
      { language: 'nl', country: 'BE', salesOrg: 'BE', flag: 'be' },
      { language: 'fr', country: 'LU', salesOrg: 'LU', flag: 'lu' },
      { language: 'de', country: 'LU', salesOrg: 'LU', flag: 'lu' },
      { language: 'it', country: 'IT', salesOrg: 'IT', flag: 'it' },
      { language: 'de', country: 'IT', salesOrg: 'IT', flag: 'it' },
      { language: 'es', country: 'ES', salesOrg: 'ES', flag: 'es' },
      { language: 'tr', country: 'TR', salesOrg: 'TR', flag: 'tr' },
      { language: 'en', country: 'GB', salesOrg: 'GB', flag: 'gb' },
      { language: 'en', country: 'US', salesOrg: 'US', flag: 'us' },
      { language: 'en', country: 'CA', salesOrg: 'CA', flag: 'ca' },
    ],
  },
  pim: {
    baseUrl: 'https://api-staging.viessmann.com/product-data/graphql',
    workspaces: ['Main', 'Approved'],
    topClassification: 'App_Structure',
    classificationRef: 'REF_ProductToOutputStructure',
    productType: {
      productImageRef: 'PrimaryProductImage',
      productImageSubtypeId: ['130', '110', '120'], //Produktbild mit Hintergrund, Freisteller (Frontale), Freisteller(Ansicht)
    },
  },
  auth: {
    appId: 'product-browser',
    baseUrl: 'https://api-staging.viessmann.com',
    registerBaseUrl: 'https://account-staging.viessmann.com',
  },
  http: {
    translationBaseUrl: 'https://api-staging.viessmann.com/translations/v2/',
  },
  globalHeader: {
    baseUrl: 'https://api-staging.viessmann.com',
    accountAppUrl: 'https://account-staging.viessmann.com',
  },
};
