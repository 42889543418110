import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription, switchMap } from 'rxjs';
import { ClassificationData } from 'src/app/modules/pim-graphql/views/classification-view/classification.model';
import { ClassificationService } from 'src/app/modules/pim-graphql/views/classification-view/classification.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-classification',
  templateUrl: './classification.component.html',
  styleUrls: ['./classification.component.scss'],
})
export class ClassificationComponent implements OnInit, OnDestroy {
  classification?: ClassificationData;
  loading = true;
  private paramSubscription?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private classificationService: ClassificationService,
    private refreshService: RefreshService,
  ) {}

  ngOnInit(): void {
    this.paramSubscription = combineLatest([
      this.route.params,
      this.refreshService.refreshData$,
    ])
      .pipe(
        switchMap((values) => {
          const id: string = values[0]['classificationId'];
          this.classification = undefined;
          this.loading = true;
          return this.classificationService.getClassificationData(id);
        }),
      )
      .subscribe((classification) => {
        this.classification = classification;
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.paramSubscription?.unsubscribe();
  }

  isStartPage(): boolean {
    if (!this.loading && !!this.classification) {
      return this.classification.topClassification;
    } else {
      return (
        this.route.snapshot.paramMap.get('classificationId') ===
        environment.pim.topClassification
      );
    }
  }
}
