import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.getAuthorizedIdentityTypes$().pipe(
    tap((hasAccess) => {
      if (!hasAccess) {
        router.navigate(['/forbidden']);
      }
    }),
  );
};
