import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { AttributeTableComponent } from './components/attribute-table/attribute-table.component';
import { DetailComponentComponent } from './components/detail-component/detail-component.component';
import { DetailPackageComponent } from './components/detail-package/detail-package.component';
import { DetailSetComponent } from './components/detail-set/detail-set.component';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { ImageFullscreenComponent } from './components/image-carousel/image-fullscreen/image-fullscreen.component';
import { LegendTextsComponent } from './components/legend-texts/legend-texts.component';
import { RelationsGroupTableComponent } from './components/relations/relations-group-table/relations-group-table.component';
import { RelationsTableComponent } from './components/relations/relations-table/relations-table.component';
import { RelationsComponent } from './components/relations/relations.component';
import { TextsComponent } from './components/texts/texts.component';
import { UsageExamplesComponent } from './components/usage-examples/usage-examples.component';
import { DetailRoutingModule } from './detail-routing.module';

const material = [
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatTableModule,
  MatSortModule,
  MatTabsModule,
  MatSelectModule,
];

@NgModule({
  declarations: [
    DetailSetComponent,
    AttributeTableComponent,
    TextsComponent,
    ImageCarouselComponent,
    ImageFullscreenComponent,
    RelationsComponent,
    RelationsTableComponent,
    UsageExamplesComponent,
    LegendTextsComponent,
    RelationsGroupTableComponent,
    DetailPackageComponent,
    DetailComponentComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    DetailRoutingModule,
    TranslateModule.forChild(),
    FlexLayoutModule,
    NgxSkeletonLoaderModule,
    NgxPanZoomModule,
    PipesModule,
    ...material,
  ],
})
export class DetailModule {}
