<ng-container *ngIf="!loading; else skeleton">
  <mat-card *ngIf="checkHide()">
    <mat-card-title fxLayout="row wrap" fxLayoutAlign="space-between center">
      <span>
        {{ attributeGroups?.title }}
      </span>
      <mat-form-field
        *ngIf="availableChannels.length > 0"
        class="channel-select"
        appearance="fill"
      >
        <mat-label>{{
          "detail.product_attributes.header.channel" | translate
        }}</mat-label>
        <mat-select
          (selectionChange)="onSelectionChange(sort, $event.value)"
          [(value)]="selectedChannelId"
        >
          <mat-option></mat-option>
          <mat-option
            *ngFor="let channel of availableChannels"
            [value]="channel.id"
          >
            {{ channel.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-title>
    <div class="vertical-scroll-wrapper">
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z0"
        matSort
        (matSortChange)="onSelectionChange($event, selectedChannelId)"
        #sort="matSort"
      >
        <!-- Attributes -->
        <ng-container matColumnDef="attribute">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "detail.product_attributes.attribute" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.attribute }}</td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "detail.product_attributes.value" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.value | decimal: row.validationType }}
          </td>
        </ng-container>

        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "detail.product_attributes.unit" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.unit }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [title]="row.attributeId"
        ></tr>

        <!-- Group header -->
        <ng-container matColumnDef="groupHeader">
          <td mat-cell colspan="999" *matCellDef="let groupHeader">
            <ng-container *ngIf="!groupHeader.translatable; else translate">
              <strong>{{ groupHeader.title }}</strong>
            </ng-container>
            <ng-template #translate>
              <strong>{{ groupHeader.title | translate }}</strong>
            </ng-template>
          </td>
        </ng-container>

        <tr
          mat-row
          *matRowDef="let row; columns: ['groupHeader']; when: isGroupHeader"
        ></tr>
      </table>
    </div>
  </mat-card>
</ng-container>
<ng-template #skeleton>
  <ngx-skeleton-loader
    [theme]="{ height: '300px', width: '100%' }"
  ></ngx-skeleton-loader>
</ng-template>
