import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetailComponentComponent } from './components/detail-component/detail-component.component';
import { DetailPackageComponent } from './components/detail-package/detail-package.component';
import { DetailSetComponent } from './components/detail-set/detail-set.component';

const routes: Routes = [
  {
    path: 'set/:setId',
    component: DetailSetComponent,
  },
  {
    path: 'package/:packageId',
    component: DetailPackageComponent,
  },
  {
    path: 'component/:componentId',
    component: DetailComponentComponent,
  },
  {
    path: '**',
    redirectTo: '../',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DetailRoutingModule {}
