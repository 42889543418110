<div class="root" fxLayout="column">
  <div fxFlex="nogrow">
    <app-nav-header
      (toggleDrawer)="drawer.toggle()"
      [forbidden]="forbidden"
    ></app-nav-header>
  </div>
  <mat-drawer-container fxFlex>
    <mat-drawer #drawer mode="side">
      <app-filters (closeDrawer)="drawer.close()"></app-filters>
    </mat-drawer>
    <mat-drawer-content fxLayout="column">
      <div fxFlex class="container gap">
        <router-outlet></router-outlet>
      </div>
      <div class="footer" fxFlex="nogrow">
        <dsw-footer>
          <div after-links>
            <app-locale-dropdown
              [selectedLocale]="localeService.getCurrentLocale()"
              (localeChanged)="localeService.setLocale($event, true)"
            ></app-locale-dropdown>
          </div>
        </dsw-footer>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
