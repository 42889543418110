import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { filter, Subscription, switchMap, tap } from 'rxjs';
import { DetailImageGroup } from 'src/app/modules/pim-graphql/views/detail-view/detail-image-view/detail-image.model';
import { DetailImageService } from 'src/app/modules/pim-graphql/views/detail-view/detail-image-view/detail-image.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
})
export class ImageCarouselComponent implements OnChanges, OnDestroy {
  @Input() productId?: string;
  imageGroups: DetailImageGroup[] = [];
  selectedGroup?: DetailImageGroup;
  selectedImage = 0;
  loading = true;
  fullscreenView = false;
  private refresh$?: Subscription;

  constructor(
    private detailImageService: DetailImageService,
    private refreshService: RefreshService,
  ) {}

  ngOnChanges(): void {
    this.refresh$?.unsubscribe();
    this.refresh$ = this.refreshService.refreshData$
      .pipe(
        filter(() => !!this.productId),
        tap(() => (this.loading = true)),
        switchMap(() =>
          this.detailImageService.getDetailImages(this.productId ?? ''),
        ),
      )
      .subscribe((imgGroups) => {
        this.imageGroups = imgGroups;
        if (this.imageGroups.length > 0) {
          this.selectedGroup = this.imageGroups[0];
        }
        this.selectedImage = 0;
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.refresh$?.unsubscribe();
  }

  onSelectionChange(group: DetailImageGroup): void {
    this.selectedGroup = group;
    this.selectedImage = 0;
  }

  next() {
    if (!this.selectedGroup?.images) return;
    if (this.selectedImage == this.selectedGroup.images.length - 1) {
      this.selectedImage = 0;
    } else {
      this.selectedImage++;
    }
  }

  prev() {
    if (!this.selectedGroup?.images) return;
    if (this.selectedImage == 0) {
      this.selectedImage = this.selectedGroup.images.length - 1;
    } else {
      this.selectedImage--;
    }
  }

  checkHide() {
    return this.imageGroups && this.imageGroups.length > 0;
  }
}
