import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from 'src/app/services/locale.service';

@Pipe({
  name: 'decimal',
})
export class StiboDecimalPipe implements PipeTransform {
  constructor(private locales: LocaleService) {}

  transform(value: string | undefined, validationType?: string): string | null {
    if (value == null) return '';

    const pipe = new DecimalPipe(this.locales.getCurrentLocaleString());

    switch (validationType) {
      case 'integer':
        return pipe.transform(value, '1.0');
      case 'number':
      case 'Number':
        return pipe.transform(value, '1.0-2');
      case 'numberrange':
        return this.transformRange(value, pipe);
      default:
        return value;
    }
  }

  private transformRange(value: string, pipe: DecimalPipe): string {
    const values = value.split('-');
    const begin = Number(values[0]);
    const end = Number(values[1]);
    if (isNaN(begin) || isNaN(end)) {
      return value;
    }
    return `${pipe.transform(begin, '1.1-1')} - ${pipe.transform(
      end,
      '1.1-1',
    )}`;
  }
}
