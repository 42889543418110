<table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Id</th>
    <td mat-cell *matCellDef="let element">{{ element.title }}</td>
  </ng-container>

  <ng-container matColumnDef="{{ image.id }}" *ngIf="!!image">
    <th mat-header-cell *matHeaderCellDef>{{ image.title }}</th>
    <td mat-cell *matCellDef="let element" class="thumbnail">
      <img *ngIf="!!element.image" [src]="element.image.imgLink" />
    </td>
  </ng-container>

  <ng-container
    *ngFor="let attribute of attributes; let i = index"
    matColumnDef="{{ attribute.id }}"
  >
    <th [fxHide.lt-sm]="i > 0" mat-header-cell *matHeaderCellDef>
      {{ attribute.title }}
    </th>
    <td [fxHide.lt-sm]="i > 0" mat-cell *matCellDef="let element">
      <span [innerHTML]="getValue(element, attribute.id) | stibotags"></span>
    </td>
  </ng-container>

  <tr class="header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [routerLink]="row.link"
    class="row-clickable"
  ></tr>
</table>
