<ng-container *ngIf="!loading; else skeleton">
  <mat-card *ngIf="checkHide()">
    <mat-card-title>{{ legendData?.title }}</mat-card-title>
    <mat-tab-group dynamicHeight>
      <mat-tab
        *ngFor="let legendText of legendData?.legends"
        label="{{ legendText.title }}"
      >
        <div>
          <div
            class="legend-text"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutGap="16px"
          >
            <img
              *ngIf="legendText.crossSectionImage"
              class="cross-section-image"
              [src]="legendText.crossSectionImage"
            />
            <div>
              <table>
                <tr *ngFor="let text of legendText.texts">
                  <td class="legend">{{ text.number }}</td>
                  <td class="legend">{{ text.text }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</ng-container>

<ng-template #skeleton>
  <ngx-skeleton-loader
    [theme]="{ height: '300px', width: '100%' }"
  ></ngx-skeleton-loader>
</ng-template>
