import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ForbiddenRoutingModule } from './forbidden-routing.module';
import { ForbiddenComponent } from './forbidden.component';

@NgModule({
  declarations: [ForbiddenComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ForbiddenRoutingModule,
  ],
  exports: [ForbiddenComponent],
})
export class ForbiddenModule {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
