<app-shared-header
  [headerData]="headerData"
  [loading]="loading"
></app-shared-header>
<div fxLayout="column" class="cards-container">
  <app-image-carousel [productId]="componentId"> </app-image-carousel>

  <app-texts [productId]="componentId"> </app-texts>

  <app-attribute-table [productId]="componentId"> </app-attribute-table>
</div>
