import { Component, Input, OnInit } from '@angular/core';
import { CardData } from '../../pim-graphql/views/shared-view.model';

@Component({
  selector: 'app-shared-card',
  templateUrl: './shared-card.component.html',
  styleUrls: ['./shared-card.component.scss'],
})
export class SharedCardComponent implements OnInit {
  @Input() data!: CardData;
  loading = false;

  ngOnInit(): void {
    this.loading = !!this.data.imgLink;
  }
}
