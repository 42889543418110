import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { LocaleService } from './locale.service';
import { Locale } from 'src/environments/environment.model';

export const localResolver: ResolveFn<Locale> = (
  route: ActivatedRouteSnapshot,
) => {
  const localeService = inject(LocaleService);
  const locale = {
    language: route.paramMap.get('lang') ?? '*',
    country: route.paramMap.get('country') ?? '*',
  };

  if (!localeService.isSameLocale(locale, localeService.getCurrentLocale())) {
    if (locale.language === '*' && locale.country === '*') {
      localeService.setLocale();
    } else {
      localeService.setLocale(locale);
    }
  }
  return localeService.getCurrentLocale();
};
