import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stibotags',
})
export class StiboPipe implements PipeTransform {
  transform(value?: string): string | undefined {
    return value
      ?.replace(/\n/g, '')
      .replace(/<lt\/>/g, '<')
      .replace(/<gt\/>/g, '>')
      .replace(/(<\/?)(bold)(>)/g, '$1b$3')
      .replace(/<(tab|tab-indent)\/>/g, '\t')
      .replace(/<multisep\/>/g, '<br>')
      .replace(/<LineBreak\/>/g, '<br>')
      .replace(/(<\/?)(italic)(>)/g, '$1i$3')
      .replace(/(<\/?)(bulletlist|unordered-list)(>)/g, '$1ul$3')
      .replace(/(<\/?)(bullet)(>)/g, '$1li$3')
      .replace(/(<\/?)(Tiefstellen|subscript)(>)/g, '$1sub$3');
  }
}
