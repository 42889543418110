import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { RefreshService } from './refresh.service';

@Injectable({
  providedIn: 'root',
})
export class ValidityDateService {
  public validityDate$ = new BehaviorSubject<Date | null>(new Date());

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private refreshService: RefreshService,
  ) {}

  public setValidity(nextValidity: Date | null) {
    if (!this.sameDay(nextValidity, this.getValidityDate())) {
      this.validityDate$.next(nextValidity);
      this.refreshService.initRefresh();
      this.setValidityDateParam();
    }
  }

  private sameDay(d1: Date | null, d2: Date | null): boolean {
    if (d1 === null || d2 === null) {
      return d1 === d2;
    }
    return (
      d1.getDate() === d2.getDate() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getFullYear() === d2.getFullYear()
    );
  }

  public setValidityDateParam() {
    const validity =
      this.getValidityDate() !== null
        ? this.getValidityDate()?.toISOString().split('T')[0]
        : 'null';

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        validity: validity,
      },
    });
  }

  public getValidityDate(): Date | null {
    return this.validityDate$.getValue();
  }
}
