import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription, switchMap } from 'rxjs';
import { ProductData } from 'src/app/modules/pim-graphql/views/product-view/product.model';
import { ProductService } from 'src/app/modules/pim-graphql/views/product-view/product.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, OnDestroy {
  product?: ProductData;
  loading = true;
  private paramSubscription?: Subscription;

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private refreshService: RefreshService,
  ) {}

  ngOnInit(): void {
    this.paramSubscription = combineLatest([
      this.route.params,
      this.refreshService.refreshData$,
    ])
      .pipe(
        switchMap((values) => {
          const id: string = values[0]['productId'];
          this.product = undefined;
          this.loading = true;
          return this.productService.getProductData(id);
        }),
      )
      .subscribe((product) => {
        this.product = product;
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.paramSubscription?.unsubscribe();
  }
}
