import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { filter, Subscription, switchMap, tap } from 'rxjs';
import { ExamplesData } from 'src/app/modules/pim-graphql/views/detail-view/detail-usage-examples-view/detail-usage-examples.model';
import { DetailUsageExamplesService } from 'src/app/modules/pim-graphql/views/detail-view/detail-usage-examples-view/detail-usage-examples.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-usage-examples',
  templateUrl: './usage-examples.component.html',
  styleUrls: ['./usage-examples.component.scss'],
})
export class UsageExamplesComponent implements OnChanges, OnDestroy {
  @Input() productId?: string;
  examplesData?: ExamplesData;
  loading = true;
  private refresh$?: Subscription;
  constructor(
    private detailUEService: DetailUsageExamplesService,
    private refreshService: RefreshService,
  ) {}

  ngOnChanges(): void {
    this.refresh$?.unsubscribe();
    this.refresh$ = this.refreshService.refreshData$
      .pipe(
        filter(() => !!this.productId),
        tap(() => (this.loading = true)),
        switchMap(() =>
          this.detailUEService.getDetailUsageExample(this.productId ?? ''),
        ),
      )
      .subscribe((uE) => {
        this.loading = false;
        this.examplesData = uE;
      });
  }

  ngOnDestroy(): void {
    this.refresh$?.unsubscribe;
  }

  checkHide() {
    return (
      this.examplesData?.examples && this.examplesData.examples?.length > 0
    );
  }
}
