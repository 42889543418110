import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { filter, Subscription, switchMap, tap } from 'rxjs';
import {
  SAPAttributeGroup,
  SAPAttributeGroups,
  SAPAttribute,
  SAPGroupTitle,
  Channel,
} from 'src/app/modules/pim-graphql/views/detail-view/detail-attributes-view/detail-attributes.model';
import { DetailAttributesService } from 'src/app/modules/pim-graphql/views/detail-view/detail-attributes-view/detail-attributes.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-attribute-table',
  templateUrl: './attribute-table.component.html',
  styleUrls: ['./attribute-table.component.scss'],
})
export class AttributeTableComponent implements OnChanges, OnDestroy {
  @Input() productId?: string;
  attributeGroups?: SAPAttributeGroups;
  loading = true;
  private refresh$?: Subscription;

  displayedColumns: string[] = ['attribute', 'value', 'unit'];
  availableChannels: Channel[] = [];
  selectedChannelId?: string;
  dataSource = new MatTableDataSource<SAPGroupTitle | SAPAttribute>([]);
  unsortedGroups: SAPAttributeGroup[] = [];

  constructor(
    private detailAttributesService: DetailAttributesService,
    private refreshService: RefreshService,
  ) {}

  ngOnChanges(): void {
    this.refresh$?.unsubscribe();
    this.refresh$ = this.refreshService.refreshData$
      .pipe(
        filter(() => !!this.productId),
        tap(() => (this.loading = true)),
        switchMap(() =>
          this.detailAttributesService.getDetailAttributes(
            this.productId ?? '',
          ),
        ),
      )
      .subscribe((attrGroups) => {
        this.loading = false;
        this.attributeGroups = attrGroups;
        this.availableChannels = this.collectChannels(
          this.attributeGroups.groups,
        );
        this.selectedChannelId = undefined;

        //deep copy to prevent sort() from overwriting order of the array
        this.unsortedGroups = this.attributeGroups.groups.map((x) => {
          return {
            groupTitle: x.groupTitle,
            channels: x.channels,
            attributes: x.attributes?.map((x) => x),
          };
        });

        this.dataSource.data = this.flattenGroupsByChannelId(
          attrGroups.groups,
          this.selectedChannelId,
        );
      });
  }

  private collectChannels(groups: SAPAttributeGroup[]): Channel[] {
    return groups.reduce<Channel[]>((channels, group) => {
      group.channels.forEach((channel) => {
        if (!channels.find((c) => c.id === channel.id)) {
          channels.push(channel);
        }
      });
      return channels;
    }, []);
  }

  private flattenGroupsByChannelId(
    groups: SAPAttributeGroup[],
    channelId?: string,
    sortDirection?: SortDirection,
    sortAttribute?: string,
  ): (SAPGroupTitle | SAPAttribute)[] {
    return groups.reduce<(SAPGroupTitle | SAPAttribute)[]>((flat, group) => {
      if (
        !group.channels.some((channel) => channel.id == channelId) &&
        (group.channels.length != 0 || channelId != undefined)
      ) {
        return flat;
      }

      flat.push(group.groupTitle);

      let values = group.attributes;
      if (values) {
        if (sortDirection && sortAttribute) {
          values = this.sort(values, sortDirection, sortAttribute);
        }
        Array.prototype.push.apply(flat, values);
      }
      return flat;
    }, []);
  }

  private sort(
    values: SAPAttribute[],
    sortDirection: SortDirection,
    sortAttribute: string,
  ): SAPAttribute[] {
    return values.sort((a: SAPAttribute, b: SAPAttribute) => {
      const asc = sortDirection === 'asc';
      switch (sortAttribute) {
        case 'attribute':
          return this.compare(asc, a.attribute, b.attribute);
        case 'value':
          return this.compare(asc, a.value, b.value);
        case 'unit':
          return this.compare(asc, a.unit, b.unit);
        default:
          return 0;
      }
    });
  }

  private compare(asc: boolean, a?: string, b?: string): number {
    const cmpA = a == null || isNaN(+a) ? a : +a;
    const cmpB = b == null || isNaN(+b) ? b : +b;
    const prioA = this.checkTypePrio(cmpA);
    const prioB = this.checkTypePrio(cmpB);
    let ret;

    if (prioA !== prioB) {
      ret = prioA - prioB;
    } else if (cmpA == null || cmpB == null) {
      ret = 1;
    } else {
      ret = cmpA > cmpB ? 1 : -1;
    }
    return ret * (asc ? 1 : -1);
  }

  private checkTypePrio(cmp: unknown): number {
    switch (typeof cmp) {
      case 'number':
        return 0;
      case 'string':
        return 1;
      default:
        return 2;
    }
  }

  onSelectionChange(sort: Sort, channelId?: string) {
    if (this.attributeGroups) {
      if (!sort.direction || !sort.active) {
        this.dataSource.data = this.flattenGroupsByChannelId(
          this.unsortedGroups,
          channelId,
        );
        return;
      }
      this.dataSource.data = this.flattenGroupsByChannelId(
        this.attributeGroups.groups,
        channelId,
        sort.direction,
        sort.active,
      );
    }
  }

  ngOnDestroy(): void {
    this.refresh$?.unsubscribe();
  }

  isGroupHeader(
    _index: number,
    item: SAPGroupTitle | SAPAttribute,
  ): item is SAPGroupTitle {
    return 'translatable' in item;
  }

  checkHide() {
    return this.attributeGroups && this.attributeGroups?.groups.length > 0;
  }
}
