import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Product } from '../../pim-graphql.model';
import { PimGraphqlService } from '../../pim-graphql.service';
import { SearchResult, SearchResultGroup } from './search.model';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private pim: PimGraphqlService) {}

  search(term: string): Observable<SearchResultGroup[]> {
    return this.pim.searchByTerm(`${term}*`).pipe(
      map((products) => {
        return this.setSearchResult(products);
      }),
    );
  }

  private setSearchResult(products: Product[]): SearchResultGroup[] {
    const results: SearchResult[] = products.map((product) => {
      const desc =
        product.objectType.id === 'OT_Set' ||
        product.objectType.id === 'OT_Package'
          ? product.values?.find((val) => val.attribute.id === 'AT_MAKTX')
              ?.simpleValue
          : '';
      return {
        id: product.id,
        title: product.title,
        description: desc,
        type: {
          id: product.objectType.id,
          title: product.objectType.title,
        },
      };
    });
    const groups: SearchResultGroup[] = [];
    results.forEach((res) => {
      const group = groups.find((g) => g.title === res.type.title);
      if (!group) {
        groups.push({
          title: res.type.title,
          results: [res],
        });
      } else {
        group.results.push(res);
      }
    });
    return groups;
  }
}
