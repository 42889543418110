import { Component, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  RelGroup,
  RelGroupTitle,
} from 'src/app/modules/pim-graphql/views/detail-view/detail-relations-view/detail-relations.model';
import { RelProduct } from 'src/app/modules/pim-graphql/views/shared-view.model';

@Component({
  selector: 'app-relations-group-table',
  templateUrl: './relations-group-table.component.html',
  styleUrls: ['./relations-group-table.component.scss'],
})
export class RelationsGroupTableComponent implements OnChanges {
  @Input() relationGroups!: RelGroup[];
  displayedColumns = ['title', 'text'];
  dataSource = new MatTableDataSource<RelGroupTitle | RelProduct>([]);

  ngOnChanges(): void {
    if (
      this.relationGroups.some((group) =>
        group.relations.some((rel) => !!rel.imageUrl),
      )
    ) {
      this.displayedColumns = ['image', 'title', 'text'];
    } else {
      this.displayedColumns = ['title', 'text'];
    }
    this.dataSource.data = this.flattenGroups(this.relationGroups);
  }

  private flattenGroups(groups: RelGroup[]): (RelGroupTitle | RelProduct)[] {
    return groups.reduce<(RelGroupTitle | RelProduct)[]>((flat, group) => {
      flat.push(group.groupTitle);

      if (group.relations) {
        Array.prototype.push.apply(flat, group.relations);
      }
      return flat;
    }, []);
  }

  isGroupHeader(
    _index: number,
    item: RelGroupTitle | RelProduct,
  ): item is RelGroupTitle {
    return 'translatable' in item;
  }
}
