import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Locale } from 'src/environments/environment.model';

@Component({
  selector: 'app-locale-dropdown',
  templateUrl: './locale-dropdown.component.html',
  styleUrls: ['./locale-dropdown.component.scss'],
})
export class LocaleDropdownComponent {
  @Input()
  public selectedLocale!: Locale;

  @Output()
  public localeChanged = new EventEmitter<Locale>();

  public availableLocales = environment.app.supportedLocales;

  localeString(locale: Locale) {
    return `${locale.language}-${locale.country}`;
  }

  public selectLocale(locale: Locale) {
    this.localeChanged.emit(locale);
  }
}
