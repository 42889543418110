<app-shared-header
  [headerData]="headerData"
  [loading]="loading"
></app-shared-header>
<div fxLayout="column" class="cards-container">
  <app-image-carousel [productId]="packageId"> </app-image-carousel>

  <app-texts [productId]="packageId"> </app-texts>

  <app-usage-examples [productId]="packageId"></app-usage-examples>

  <app-relations [productId]="packageId"></app-relations>

  <app-attribute-table [productId]="packageId"> </app-attribute-table>
</div>
