import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from 'src/app/services/locale.service';

@Pipe({
  name: 'pim-route',
})
export class PimRoutePipe implements PipeTransform {
  constructor(private locales: LocaleService) {}
  transform(id: string, objectType: string): string[] | undefined {
    const link = [
      `/${this.locales.getCurrentLocale().language.toLowerCase()}`,
      this.locales.getCurrentLocale().country.toLowerCase(),
    ];
    switch (objectType) {
      case 'OT_Set':
        link.push('detail', 'set');
        break;
      case 'OT_Package':
        link.push('detail', 'package');
        break;
      case 'OT_Component':
        link.push('detail', 'component');
        break;
      case 'OT_PackageType':
        link.push('overview', 'packagetype');
        break;
      case 'OT_ProductType':
        link.push('overview', 'producttype');
        break;
      case 'OT_Product':
        link.push('overview', 'product');
        break;
      case 'App_Structure':
        link.push('overview', 'classification');
        break;
      default:
        return;
    }
    link.push(id);
    return link;
  }
}
