import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
} from '@angular/core';
import { PanZoomAPI, PanZoomConfig } from 'ngx-panzoom';
import { Subscription } from 'rxjs';
import { DetailImage } from 'src/app/modules/pim-graphql/views/detail-view/detail-image-view/detail-image.model';

@Component({
  selector: 'app-image-fullscreen',
  templateUrl: './image-fullscreen.component.html',
  styleUrls: ['./image-fullscreen.component.scss'],
})
export class ImageFullscreenComponent implements OnInit, OnDestroy {
  @Input() image?: DetailImage;
  @Input() enableControls: boolean = false;
  @Output() fSClose = new EventEmitter();
  @Output() fSNext = new EventEmitter();
  @Output() fSPrev = new EventEmitter();

  panZoomConfig: PanZoomConfig;
  private panZoomAPI: PanZoomAPI | undefined;
  private apiSubscription: Subscription | undefined;

  constructor() {
    this.panZoomConfig = new PanZoomConfig({
      freeMouseWheelFactor: 0.0015,
    });
  }

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.apiSubscription = this.panZoomConfig.api.subscribe(
      (api: PanZoomAPI) => {
        this.panZoomAPI = api;
      },
    );
  }

  closeFullscreen() {
    this.fSClose.emit();
  }

  ngOnDestroy(): void {
    document.body.style.overflow = 'auto';
    if (this.apiSubscription) this.apiSubscription.unsubscribe();
  }

  onLoad() {
    this.panZoomAPI?.resetView();
  }
}
