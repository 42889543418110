import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ActivatedRoute } from '@angular/router';
import moment, { Moment } from 'moment';
import { debounceTime, distinctUntilChanged, map, Subscription } from 'rxjs';
import { ValidityDateService } from 'src/app/services/validity-date.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Output() closeDrawer = new EventEmitter();
  workspaces = environment.pim.workspaces;
  selectedWorkspace = this.workspaceService.currentWorkspace$;

  dateSubscription?: Subscription;
  filterSubscription?: Subscription;
  dateForm = new FormControl<Moment | null>(null);

  constructor(
    public workspaceService: WorkspaceService,
    public validityDateService: ValidityDateService,
    public route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      // Set Validity Date
      const validity = params.get('validity');
      if (validity) {
        this.validityDateService.setValidity(
          validity !== 'null' ? new Date(validity) : null,
        );
      }
      // Set Workspace
      const workspace = params.get('workspace');
      if (
        workspace &&
        workspace !== this.workspaceService.getCurrentWorkspace()
      ) {
        this.workspaceService.setWorkspace(workspace);
      }
    });

    this.filterSubscription = this.dateForm.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map((date) => {
          return date ? date.startOf('day').toDate() : null;
        }),
      )
      .subscribe((utcDate) => {
        this.validityDateService.setValidity(utcDate);
      });

    this.dateSubscription = this.validityDateService.validityDate$.subscribe(
      (date) => {
        if (date) {
          this.dateForm.patchValue(moment(date));
        }
      },
    );
  }

  ngOnDestroy(): void {
    this.dateSubscription?.unsubscribe();
    this.filterSubscription?.unsubscribe();
  }

  onClearDate(event: Event) {
    event.stopPropagation();
    this.dateForm.setValue(null);
  }

  onChangedWorkspace(event: MatButtonToggleChange) {
    this.workspaceService.setWorkspace(event.value);
  }

  clickCloseDrawer() {
    this.closeDrawer.emit();
  }
}
