import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, Observable, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  private apiHost: string;
  private pimHost: string;
  constructor(private apiAuth: AuthService) {
    this.apiHost = new URL(environment.apiUrl).host;
    this.pimHost = new URL(environment.pim.baseUrl).host;
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<HttpEventType.Response>> {
    request = request.clone({
      withCredentials: false,
    });

    if (
      request.url.includes(this.pimHost) ||
      request.url.includes(this.apiHost)
    ) {
      return this.apiAuth.csrfToken$.pipe(
        first((val) => {
          return val !== undefined;
        }),
        switchMap((value) => {
          return next.handle(
            request.clone({
              headers: request.headers.set('Authorization', `CSRF ${value}`),
            }),
          );
        }),
        catchError((e) => {
          if (e instanceof HttpErrorResponse && e.status === 401) {
            // refresh CSRF token and retry active request if fetching of token succeed
            return this.apiAuth
              .fetchNewCsrfToken()
              .pipe(switchMap(() => this.intercept(request, next)));
          }
          throw e;
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
