<app-shared-header
  [headerData]="classification?.headerData"
  [loading]="loading"
  [startPage]="isStartPage()"
>
</app-shared-header>
<ng-container *ngIf="!loading; else skeleton">
  <div fxLayout="column" fxLayoutGap="16px grid">
    <div
      *ngIf="!!classification?.classifications"
      fxLayout="row wrap"
      fxLayoutGap="16px 16px grid"
      fxLayout.lt-sm="column"
      fxLayoutGap.lt-sm="0 16px grid"
    >
      <app-shared-card
        *ngFor="let class of classification?.classifications"
        fxFlex.gt-xs="50"
        [data]="class"
      >
      </app-shared-card>
    </div>

    <div
      *ngIf="!!classification?.products"
      fxLayout="row wrap"
      fxLayoutGap="16px 16px grid"
      fxLayout.lt-sm="column"
      fxLayoutGap.lt-sm="0 16px grid"
    >
      <app-shared-card
        *ngFor="let product of classification?.products"
        fxFlex.gt-xs="50"
        [data]="product"
      >
      </app-shared-card>
    </div>

    <div
      *ngIf="!!classification?.packageTypes"
      fxLayout="row wrap"
      fxLayoutGap="16px 16px grid"
      fxLayout.lt-sm="column"
      fxLayoutGap.lt-sm="0 16px grid"
    >
      <app-shared-card
        *ngFor="let package of classification?.packageTypes"
        fxFlex.gt-xs="50"
        [data]="package"
      >
      </app-shared-card>
    </div>

    <div
      *ngIf="
        !!classification &&
        !!classification.sets &&
        classification.sets.length > 0
      "
    >
      <app-shared-list [entries]="classification.sets"></app-shared-list>
    </div>
  </div>
</ng-container>
<ng-template #skeleton>
  <div
    fxLayout="row wrap"
    fxLayoutGap="16px 16px grid"
    fxLayout.lt-sm="column"
    fxLayoutGap.lt-sm="0 16px grid"
  >
    <ngx-skeleton-loader
      *ngFor="let i of [].constructor(5)"
      fxFlex.gt-xs="50"
      [theme]="{ height: '5em', 'margin-bottom': '0' }"
    ></ngx-skeleton-loader>
  </div>
</ng-template>
