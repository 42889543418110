<div fxLayout="column" class="drawer" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h4>
      {{ "drawer.filters.title" | translate }}
    </h4>
    <button mat-icon-button (click)="clickCloseDrawer()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div>
    <h5 class="filter-heading">Workspace</h5>
    <mat-button-toggle-group
      fxFlexAlign="center"
      [value]="selectedWorkspace | async"
      (change)="onChangedWorkspace($event)"
    >
      <mat-button-toggle
        *ngFor="let workspace of workspaces"
        value="{{ workspace }}"
        >{{ workspace }}</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
  <div>
    <h5 class="filter-heading">
      {{ "drawer.filters.validity_date" | translate }}
    </h5>
    <mat-form-field appearance="fill">
      <mat-label>{{ "drawer.filters.date" | translate }}</mat-label>
      <input matInput [matDatepicker]="picker" [formControl]="dateForm" />
      <mat-hint>YYYY-MM-DD</mat-hint>
      <mat-datepicker-toggle
        matSuffix
        (click)="onClearDate($event)"
        *ngIf="dateForm.value"
      >
        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker startView="multi-year" #picker></mat-datepicker>
    </mat-form-field>
  </div>
</div>
