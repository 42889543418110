import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription, switchMap } from 'rxjs';
import { DetailService } from 'src/app/modules/pim-graphql/views/detail-view/detail-view/detail.service';
import { HeaderData } from 'src/app/modules/pim-graphql/views/shared-view.model';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-detail-component',
  templateUrl: './detail-component.component.html',
  styleUrls: ['./detail-component.component.scss'],
})
export class DetailComponentComponent implements OnInit, OnDestroy {
  componentId!: string;
  headerData?: HeaderData;
  loading = true;
  private paramSubscription?: Subscription;

  constructor(
    private detailService: DetailService,
    private route: ActivatedRoute,
    private refreshService: RefreshService,
  ) {}

  ngOnInit(): void {
    this.paramSubscription = combineLatest([
      this.route.params,
      this.refreshService.refreshData$,
    ])
      .pipe(
        switchMap((values) => {
          this.componentId = values[0]['componentId'];
          this.headerData = undefined;
          this.loading = true;

          return this.detailService.getDetailHeader(this.componentId);
        }),
      )
      .subscribe((detailData) => {
        this.headerData = detailData;
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.paramSubscription?.unsubscribe();
  }
}
