import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Locale, SearchLocale } from 'src/environments/environment.model';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  readonly defaultLocale = environment.app.defaultLocale;
  readonly supportedLocales = environment.app.supportedLocales;

  public currentLocale$: BehaviorSubject<Locale> = new BehaviorSubject<Locale>(
    this.defaultLocale,
  );

  constructor(
    private translate: TranslateService,
    private router: Router,
    private apollo: Apollo,
  ) {
    this.translate.setDefaultLang(this.getCurrentLocaleString());
  }

  setLocale(locale?: SearchLocale, forceUrlChange?: boolean): void {
    let supportedLocale = this.findSupportedLocale(
      locale ?? {
        language: navigator.language.split('-')[0] ?? '',
        country: navigator.language.split('-')[1] ?? '',
      },
    );

    if (!supportedLocale) {
      supportedLocale = this.defaultLocale;
    }
    this.currentLocale$.next(supportedLocale);
    this.translate.use(this.getCurrentLocaleString());

    if (
      !locale ||
      !this.isSameLocale(locale, supportedLocale) ||
      forceUrlChange
    ) {
      this.apollo.client.resetStore();
      this.router.navigate([
        supportedLocale.language.toLowerCase(),
        supportedLocale.country.toLowerCase(),
      ]);
    }
  }

  getCurrentLocale(): Locale {
    return this.currentLocale$.value;
  }

  public findSupportedLocale(locale: SearchLocale): Locale | undefined {
    let supportedLocale = this.supportedLocales.find((l) =>
      this.isSameLocale(locale, l),
    );

    if (supportedLocale) {
      return supportedLocale;
    }

    supportedLocale = this.supportedLocales.find(
      (l) => l.language === locale.language,
    );

    return supportedLocale;
  }

  public getCurrentLocaleString() {
    return `${this.currentLocale$.value.language}-${this.currentLocale$.value.country}`;
  }

  public isSameLocale(l1: SearchLocale, l2: Locale) {
    return (
      l1.language.toLowerCase() === l2.language.toLowerCase() &&
      l1.country.toLowerCase() === l2.country.toLowerCase()
    );
  }
}
