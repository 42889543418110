import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Product } from '../../../pim-graphql.model';
import { PimGraphqlService } from '../../../pim-graphql.service';
import { ExamplesData } from './detail-usage-examples.model';
import { StiboPipe } from 'src/app/modules/pipes/stibo.pipe';

@Injectable({
  providedIn: 'root',
})
export class DetailUsageExamplesService {
  constructor(
    private pim: PimGraphqlService,
    private stiboTags: StiboPipe,
  ) {}

  getDetailUsageExample(id: string): Observable<ExamplesData | undefined> {
    return this.pim.getProductUsageExampleById(id).pipe(
      map((product) => {
        return this.getUsageExample(product);
      }),
    );
  }

  private getUsageExample(product: Product): ExamplesData | undefined {
    const ref = product.referencesByReferenceType?.find(
      (ref) => ref.referenceType.id === 'REF_ApplicationExample',
    );

    const examples = ref?.referenceEntries.map((ex) => {
      const systemImage = ex.target.referencesByReferenceType
        ?.find((ref) => ref.referenceType.id === 'REF_SystemImage')
        ?.referenceEntries?.[0]?.target.values?.find(
          (val) => val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_1200',
        )?.simpleValue;

      const accessories = ex.target.referencesByReferenceType
        ?.find((ref) => {
          return 'REF_Accessories' === ref.referenceType.id;
        })
        ?.referenceEntries.map((accessory) => {
          return {
            title: accessory.target.title,
            text: accessory.target.values?.find(
              (val) => 'AT_MAKTX' === val.attribute.id,
            )?.simpleValue,
            imageUrl: accessory.target.primaryImage?.values?.find(
              (val) =>
                val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_400',
            )?.simpleValue,
          };
        });

      const text = {
        title: ex.target.values?.find(
          (val) => val.attribute.id === 'AT_TB_Headline',
        )?.simpleValue,
        content: this.stiboTags.transform(
          ex.target.values?.find((val) => val.attribute.id === 'AT_TB_Content')
            ?.simpleValue,
        ),
      };

      return {
        title: ex.target.title,
        systemImage: systemImage,
        accessories: accessories,
        text: text,
      };
    });

    if (ref && examples) {
      return {
        title: ref.referenceType.title,
        examples: examples,
      };
    }
  }
}
