<div fxLayout="column">
  <app-breadcrumbs
    *ngIf="!startPage"
    fxFlex
    [breadcrumbs]="headerData?.breadcrumbs"
    [loading]="loading"
  >
  </app-breadcrumbs>
  <ng-container *ngIf="!loading; else skeleton">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <ng-container *ngIf="!startPage">
        <button
          *ngIf="headerData?.backLink"
          mat-icon-button
          [routerLink]="headerData?.backLink"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
        <button
          *ngIf="!headerData?.backLink"
          mat-icon-button
          (click)="backNav()"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
      </ng-container>
      <h2 *ngIf="!startPage; else homepage" fxFlex>{{ headerData?.title }}</h2>
      <ng-template #homepage>
        <h2 fxFlex>{{ "overview.home_page.title" | translate }}</h2>
      </ng-template>
      <img
        *ngIf="headerData?.iconUrl"
        class="energy-source-icon"
        [src]="headerData?.iconUrl"
      />
    </div>
  </ng-container>
  <ng-template #skeleton>
    <ngx-skeleton-loader
      [theme]="{
        height: '40px',
        'margin-bottom': '30px',
        'margin-top': '20px',
        width: '75%'
      }"
    >
    </ngx-skeleton-loader>
  </ng-template>
</div>
