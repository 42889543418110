import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PipesModule } from '../pipes/pipes.module';
import { SharedCardComponent } from './shared-card/shared-card.component';
import { BreadcrumbsComponent } from './shared-header/breadcrumbs/breadcrumbs.component';
import { SharedHeaderComponent } from './shared-header/shared-header.component';
import { SharedListComponent } from './shared-list/shared-list.component';

const material = [
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatRippleModule,
  MatTableModule,
];

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    SharedHeaderComponent,
    SharedCardComponent,
    SharedListComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    NgxSkeletonLoaderModule,
    RouterModule,
    PipesModule,
    ...material,
  ],
  exports: [SharedHeaderComponent, SharedCardComponent, SharedListComponent],
})
export class SharedModule {}
