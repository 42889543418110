<div>
  <button mat-button [matMenuTriggerFor]="localeMenu">
    <span class="flag flag-{{ selectedLocale.flag }}"></span>
    <span class="locale-text">{{ localeString(selectedLocale) }}</span>

    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #localeMenu overlapTrigger="false">
    <button
      *ngFor="let locale of availableLocales"
      (click)="selectLocale(locale)"
      [title]="localeString(locale)"
      mat-menu-item
    >
      <span class="flag flag-{{ locale.flag }}"></span>
      <span>
        {{
          "footer.locale_dropdown.locale." + localeString(locale) | translate
        }}</span
      >
    </button>
  </mat-menu>
</div>
