import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { filter, Subscription, switchMap, tap } from 'rxjs';
import { Relations } from 'src/app/modules/pim-graphql/views/detail-view/detail-relations-view/detail-relations.model';
import { DetailRelationsService } from 'src/app/modules/pim-graphql/views/detail-view/detail-relations-view/detail-relations.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-relations',
  templateUrl: './relations.component.html',
  styleUrls: ['./relations.component.scss'],
})
export class RelationsComponent implements OnChanges, OnDestroy {
  @Input() productId?: string;
  relations?: Relations;
  loading = true;
  private refresh$?: Subscription;

  constructor(
    private detailRelationsService: DetailRelationsService,
    private refreshService: RefreshService,
  ) {}

  ngOnChanges(): void {
    this.refresh$?.unsubscribe();
    this.refresh$ = this.refreshService.refreshData$
      .pipe(
        filter(() => !!this.productId),
        tap(() => (this.loading = true)),
        switchMap(() =>
          this.detailRelationsService.getDetailRelations(this.productId ?? ''),
        ),
      )
      .subscribe((rels) => {
        this.loading = false;
        this.relations = rels;
      });
  }

  ngOnDestroy(): void {
    this.refresh$?.unsubscribe();
  }
}
