import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { RefreshService } from './refresh.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  public currentWorkspace$: BehaviorSubject<string> =
    new BehaviorSubject<string>(environment.pim.workspaces[0]);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apollo: Apollo,
    private refreshService: RefreshService,
  ) {}

  async setWorkspace(id: string) {
    if (environment.pim.workspaces.includes(id)) {
      //Invalidate GraphQL cache to refetch data
      await this.apollo.client.resetStore();
      this.currentWorkspace$.next(id);
      this.refreshService.initRefresh();
    }
    this.setWorkspaceParam();
  }

  setWorkspaceParam() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { workspace: this.getCurrentWorkspace() },
    });
  }

  getCurrentWorkspace(): string {
    return this.currentWorkspace$.getValue();
  }
}
