import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription, switchMap } from 'rxjs';
import { ProductTypeData } from 'src/app/modules/pim-graphql/views/product-type-view/product-type.model';
import { ProductTypeService } from 'src/app/modules/pim-graphql/views/product-type-view/product-type.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-product-type',
  templateUrl: './product-type.component.html',
  styleUrls: ['./product-type.component.scss'],
})
export class ProductTypeComponent implements OnInit, OnDestroy {
  productType?: ProductTypeData;
  loading = true;
  private paramSubscription?: Subscription;

  constructor(
    private productTypeService: ProductTypeService,
    private route: ActivatedRoute,
    private refreshService: RefreshService,
  ) {}

  ngOnInit(): void {
    this.paramSubscription = combineLatest([
      this.route.params,
      this.refreshService.refreshData$,
    ])
      .pipe(
        switchMap((values) => {
          const id: string = values[0]['productTypeId'];
          this.productType = undefined;
          this.loading = true;
          return this.productTypeService.getProductTypeData(id);
        }),
      )
      .subscribe((productType) => {
        this.productType = productType;
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.paramSubscription?.unsubscribe();
  }
}
