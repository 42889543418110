import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Product } from '../../../pim-graphql.model';
import { PimGraphqlService } from '../../../pim-graphql.service';
import { LegendData, LegendText } from './detail-legend-texts.model';
import { XMLTagPipe } from 'src/app/modules/pipes/xml-tag.pipe';

@Injectable({
  providedIn: 'root',
})
export class DetailLegendTextsService {
  constructor(
    private pim: PimGraphqlService,
    private xmlTags: XMLTagPipe,
  ) {}

  getDetailLegendTexts(id: string): Observable<LegendData | undefined> {
    return this.pim.getProductLegendTextById(id).pipe(
      map((product) => {
        return this.getLegendTexts(product);
      }),
    );
  }

  private getLegendTexts(product: Product): LegendData | undefined {
    const legendRef = product.referencesByReferenceType?.find(
      (ref) => ref.referenceType.id === 'REF_LegendImage',
    );

    const legendsArr = legendRef?.referenceEntries.reduce<LegendText[]>(
      (legends, entry) => {
        const texts = entry.target?.values
          ?.find((val) => val.attribute.id === 'AT_Legend')
          ?.values.map((val) => {
            return {
              number: this.xmlTags.transform(val.value, 'legend-number'),
              text: this.xmlTags.transform(val.value, 'legend-text'),
            };
          });

        if (texts) {
          legends.push({
            title: entry.target.title,
            crossSectionImage: entry.target.primaryImage?.values?.find(
              (val) =>
                val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_1200',
            )?.simpleValue,
            texts: texts,
          });
        }

        return legends;
      },
      [],
    );

    if (legendRef && legendsArr) {
      return {
        title: legendRef.referenceType.title,
        legends: legendsArr,
      };
    }
  }
}
