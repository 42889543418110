import { Component, Input, OnChanges } from '@angular/core';
import { RelProduct } from 'src/app/modules/pim-graphql/views/shared-view.model';

@Component({
  selector: 'app-relations-table',
  templateUrl: './relations-table.component.html',
  styleUrls: ['./relations-table.component.scss'],
})
export class RelationsTableComponent implements OnChanges {
  @Input() relations!: RelProduct[];
  private minimumColumns = ['title', 'text'];
  displayedColumns: string[] = [];

  ngOnChanges(): void {
    const columns = [];
    if (this.relations.some((rel) => !!rel.posNr)) {
      columns.push('posnr');
    }
    if (this.relations.some((rel) => !!rel.imageUrl)) {
      columns.push('image');
    }
    columns.push(...this.minimumColumns);
    if (this.relations.some((rel) => !!rel.amount?.amount)) {
      columns.push('amount');
    }
    this.displayedColumns = columns;
  }
}
