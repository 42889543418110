<div fxLayout="row" fxLayoutAlign="start center" class="nav color-white">
  <p>{{ image?.title }}</p>
  <div fxFlex></div>
  <ng-container *ngIf="enableControls">
    <button class="button" mat-icon-button (click)="fSPrev.emit()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button class="button" mat-icon-button (click)="fSNext.emit()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </ng-container>
  <button class="button" mat-icon-button (click)="closeFullscreen()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="pan-zoom-content" (click)="closeFullscreen()">
  <pan-zoom [config]="panZoomConfig">
    <div class="pan-zoom-wrapper">
      <img
        [src]="image?.link"
        (load)="onLoad()"
        (click)="$event.stopPropagation()"
      />
    </div>
  </pan-zoom>
</div>
