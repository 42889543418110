<div
  *ngIf="!loading; else skeleton"
  fxLayout="row wrap"
  fxLayoutAlign="start center"
>
  <div
    *ngFor="let element of breadcrumbs; let first = first; let last = last"
    fxLayout
    fxLayoutAlign="start center"
  >
    <a *ngIf="!!first" [routerLink]="element.link">
      <mat-icon>home_filled</mat-icon>
    </a>
    <a *ngIf="!first" [routerLink]="element.link">{{ element.title }}</a>
    <mat-icon *ngIf="!last">arrow_right</mat-icon>
  </div>
</div>
<ng-template #skeleton>
  <ngx-skeleton-loader [theme]="{ width: '75%' }"></ngx-skeleton-loader>
</ng-template>
