import {
  APP_BASE_HREF,
  PathLocationStrategy,
  PlatformLocation,
} from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { UrlSerializer } from '@angular/router';

@Injectable()
export class PerserveQueryParam extends PathLocationStrategy {
  private get search(): string {
    return this.platformLocation?.search ?? '';
  }

  private validQueryParams = ['workspace', 'validity'];

  constructor(
    private platformLocation: PlatformLocation,
    private urlSerializer: UrlSerializer,
    @Optional() @Inject(APP_BASE_HREF) _baseHref?: string,
  ) {
    super(platformLocation, _baseHref);
  }

  override prepareExternalUrl(internal: string): string {
    const path = super.prepareExternalUrl(internal);
    const existingURLSearchParams = new URLSearchParams(this.search);
    const existingQueryParams = Object.fromEntries(
      existingURLSearchParams.entries(),
    );

    const toRemove: string[] = [];

    const urlTree = this.urlSerializer.parse(path);
    const nextQueryParams = Object.keys(urlTree.queryParams).reduce(
      (
        obj: {
          [k: string]: string;
        },
        key: string,
      ) => {
        const param = urlTree.queryParams[key];
        if (param !== 'null') {
          obj[key] = param;
        } else {
          toRemove.push(key);
        }
        return obj;
      },
      {},
    );

    const filtered = Object.keys(existingQueryParams)
      .filter(
        (key) => this.validQueryParams.includes(key) && !toRemove.includes(key),
      )
      .reduce(
        (
          obj: {
            [k: string]: string;
          },
          key: string,
        ) => {
          const param = existingQueryParams[key];
          if (param !== 'null') {
            obj[key] = param;
          }
          return obj;
        },
        {},
      );

    urlTree.queryParams = { ...filtered, ...nextQueryParams };
    return urlTree.toString();
  }
}
