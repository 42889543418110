import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { filter, Subscription, switchMap, tap } from 'rxjs';
import { Texts } from 'src/app/modules/pim-graphql/views/detail-view/detail-texts-view/detail-texts.model';
import { DetailTextsService } from 'src/app/modules/pim-graphql/views/detail-view/detail-texts-view/detail-texts.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-texts',
  templateUrl: './texts.component.html',
  styleUrls: ['./texts.component.scss'],
})
export class TextsComponent implements OnChanges, OnDestroy {
  @Input() productId?: string;
  textsData?: Texts;
  loading = true;
  private refresh$?: Subscription;

  constructor(
    private detailTextsService: DetailTextsService,
    private refreshService: RefreshService,
  ) {}

  ngOnChanges(): void {
    this.refresh$?.unsubscribe();
    this.refresh$ = this.refreshService.refreshData$
      .pipe(
        filter(() => !!this.productId),
        tap(() => (this.loading = true)),
        switchMap(() =>
          this.detailTextsService.getDetailTexts(this.productId ?? ''),
        ),
      )
      .subscribe((texts) => {
        this.loading = false;
        this.textsData = texts;
        this.checkHide();
      });
  }

  ngOnDestroy(): void {
    this.refresh$?.unsubscribe();
  }

  checkArrayLength(arr?: unknown[]) {
    return arr && arr.length > 0;
  }

  checkHide() {
    return (
      this.textsData?.advantageTexts ||
      this.textsData?.imageTexts ||
      this.textsData?.marketingTexts ||
      this.checkArrayLength(this.textsData?.texts)
    );
  }
}
