<ng-container *ngIf="!loading; else skeleton">
  <mat-card *ngIf="checkHide()">
    <mat-card-title>{{
      "detail.product_texts.title" | translate
    }}</mat-card-title>
    <mat-tab-group dynamicHeight>
      <!-- Advantage Texts -->
      <mat-tab
        *ngFor="let advantageText of textsData?.advantageTexts"
        label="{{ advantageText.title }}"
      >
        <div class="tab-content" fxLayout="row wrap">
          <div
            class="advantage-text"
            [class.gt-md]="'half'"
            *ngFor="let text of advantageText.textModules"
            fxLayoutAlign="start start"
            fxFlex.lt-lg="100"
            fxFlex.gt-md="50"
            fxLayoutGap="8px"
          >
            <mat-icon fxFlex="noshrink">check</mat-icon>
            <span fxFlex="grow" [innerHTML]="text.content"></span>
          </div>
        </div>
      </mat-tab>
      <!-- Marketing Texts -->
      <mat-tab
        *ngIf="textsData?.marketingTexts"
        label="{{ textsData?.marketingTexts?.title }}"
      >
        <div class="tab-content">
          <ul class="text-list">
            <li *ngFor="let text of textsData?.marketingTexts?.textModules">
              <h4>{{ text.title }}</h4>
              <ng-container *ngIf="text.content.length <= 1; else multivalued">
                <span class="whitespace" [innerHTML]="text.content[0]"></span>
              </ng-container>
              <ng-template #multivalued>
                <ul>
                  <li *ngFor="let value of text.content">
                    <span class="whitespace" [innerHTML]="value"></span>
                  </li>
                </ul>
              </ng-template>
            </li>
          </ul>
        </div>
      </mat-tab>
      <!-- Image Texts -->
      <mat-tab
        *ngFor="let imageText of textsData?.imageTexts"
        label="{{ imageText.title }}"
      >
        <div class="tab-content">
          <ul class="text-list">
            <li *ngFor="let module of imageText.textModules">
              <div
                *ngIf="
                  !!module.title ||
                  (!!module.imageUrls && module.imageUrls.length > 0)
                "
                fxLayout="row wrap"
                fxLayoutAlign="start center"
                fxLayoutGap="8px"
              >
                <h4 fxFlex [innerHTML]="module.title"></h4>
                <img
                  class="icon"
                  *ngFor="let url of module.imageUrls"
                  [src]="url"
                />
              </div>
              <span [innerHTML]="module.content"></span>
            </li>
          </ul>
        </div>
      </mat-tab>
      <!-- Angebotstext -->
      <ng-container *ngFor="let text of textsData?.texts">
        <mat-tab *ngIf="!!text" label="{{ text.title }}">
          <div
            class="tab-content whitespace offering-text"
            [innerHTML]="text.content"
          ></div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </mat-card>
</ng-container>

<ng-template #skeleton>
  <ngx-skeleton-loader
    [theme]="{ height: '300px', width: '100%' }"
  ></ngx-skeleton-loader>
</ng-template>
