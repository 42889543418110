import { LocationStrategy, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeBE from '@angular/common/locales/be';
import localeCA from '@angular/common/locales/ca';
import localeDE from '@angular/common/locales/de';
import localeES from '@angular/common/locales/es';
import localeFR from '@angular/common/locales/fr';
import localeIT from '@angular/common/locales/it';
import localeLU from '@angular/common/locales/lu';
import localeNL from '@angular/common/locales/nl';
import localePL from '@angular/common/locales/pl';
import localeTR from '@angular/common/locales/tr';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InMemoryCache } from '@apollo/client/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DswFooterComponent } from '@vi-succ/design-system-web';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DetailModule } from './modules/detail/detail.module';
import { FiltersModule } from './modules/filters/filters.module';
import { FooterModule } from './modules/footer/footer.module';
import { ForbiddenModule } from './modules/forbidden/forbidden.module';
import { NavHeaderModule } from './modules/nav-header/nav-header.module';
import { OverviewModule } from './modules/overview/overview.module';
import { PimGraphqlModule } from './modules/pim-graphql/pim-graphql.module';
import { SharedModule } from './modules/shared/shared.module';
import { AuthService } from './services/auth.service';
import { InterceptorService } from './services/interceptor.service';
import { LocaleService } from './services/locale.service';
import { PerserveQueryParam } from './services/preserve-query-param.service';

registerLocaleData(localeBE);
registerLocaleData(localeDE);
registerLocaleData(localeES);
registerLocaleData(localeFR);
registerLocaleData(localeLU);
registerLocaleData(localeNL);
registerLocaleData(localePL);
registerLocaleData(localeTR);
registerLocaleData(localeIT);
registerLocaleData(localeCA);

const phraseProjectId = '6de6d74f23cf54a50267cb2030f6a83f'; // Phrase project id
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.http.translationBaseUrl}phraseapp-proxy/projects/${phraseProjectId}/locales/`,
    '/download?file_format=i18next',
  );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    NgxSkeletonLoaderModule.forRoot(),
    FlexLayoutModule,
    PimGraphqlModule,
    NavHeaderModule,
    OverviewModule,
    DetailModule,
    FooterModule,
    FiltersModule,
    SharedModule,
    ApolloModule,
    MatSidenavModule,
    DswFooterComponent,
    MatProgressSpinner,
    ForbiddenModule,
  ],
  providers: [
    LocaleService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: `${environment.pim.baseUrl}`,
          }),
        };
      },
      deps: [HttpLink],
    },
    { provide: LocationStrategy, useClass: PerserveQueryParam },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
