import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'xml-tag',
})
export class XMLTagPipe implements PipeTransform {
  transform(value: string, tag: string): string | undefined {
    const regex = new RegExp(`<(${tag})>(.*)</\\1>`);
    const matched = regex.exec(value);
    return matched ? matched[2] : undefined;
  }
}
