import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { localResolver } from './services/locale.resolver';

const routes: Routes = [
  {
    path: 'forbidden',
    loadChildren: () =>
      import('./modules/forbidden/forbidden.module').then(
        (m) => m.ForbiddenModule,
      ),
  },
  {
    path: '',
    canActivate: [authGuard],
    children: [
      {
        path: ':lang/:country',
        resolve: {
          locale: localResolver,
        },
        children: [
          {
            path: 'overview',
            loadChildren: () =>
              import('./modules/overview/overview.module').then(
                (m) => m.OverviewModule,
              ),
          },
          {
            path: 'detail',
            loadChildren: () =>
              import('./modules/detail/detail.module').then(
                (m) => m.DetailModule,
              ),
          },
          {
            path: '**',
            redirectTo: 'overview',
          },
        ],
      },
      {
        path: '**',
        redirectTo: `*/*`,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
