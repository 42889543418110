import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Product } from '../../../pim-graphql.model';
import { PimGraphqlService } from '../../../pim-graphql.service';
import { HeaderData, PathElement } from '../../shared-view.model';
import { PimRoutePipe } from 'src/app/modules/pipes/pim-route.pipe';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DetailService {
  constructor(
    private pim: PimGraphqlService,
    private router: Router,
    private routePipe: PimRoutePipe,
  ) {}

  getDetailHeader(id: string): Observable<HeaderData | undefined> {
    return this.pim.getDetailById(id).pipe(
      map((product) => {
        if (
          !product ||
          (product.objectType.id !== 'OT_Set' &&
            product.objectType.id !== 'OT_Package' &&
            product.objectType.id !== 'OT_Component')
        ) {
          this.router.navigate(['/'], { skipLocationChange: true });
          return;
        }
        return this.getHeaderData(product);
      }),
    );
  }

  private getHeaderData(product: Product): HeaderData {
    const productPath: PathElement[] | undefined = [];
    let classification;

    for (const element of (product.path
      ? [...product.path, product]
      : [product]
    ).reverse()) {
      classification = element.referencesByReferenceType?.find((ref) => {
        return ref.referenceType.id === environment.pim.classificationRef;
      })?.referenceEntries?.[0]?.target;

      productPath?.push({
        title: element.title,
        link: this.routePipe.transform(element.id, element.objectType.id),
      });

      if (classification) {
        break;
      }
    }

    let path: PathElement[] | undefined = classification?.path
      .slice(
        classification.path.findIndex((path) => {
          return path.id === environment.pim.topClassification;
        }),
      )
      .map((x) => {
        return {
          title: x.title,
          link: this.routePipe.transform(x.id, x.objectType.id),
        };
      });

    if (classification) {
      path?.push({
        title: classification?.title ?? 'home',
        link: this.routePipe.transform(
          classification.id,
          classification.objectType.id,
        ),
      });
    }

    if (path && productPath) {
      path = [...path, ...productPath.reverse()];
    }

    //Title
    const shortDesc = product.values?.find(
      (val) => val.attribute.id === 'AT_MAKTX',
    );
    let title = product.title;
    if (shortDesc?.simpleValue) {
      title = `${product.title} - ${shortDesc?.simpleValue}`;
    }

    const icon = product.referencesByReferenceType
      ?.find((ref) => ref.referenceType.id === 'REF_Branding_Icon')
      ?.referenceEntries.find((img) =>
        img.target.values
          ?.find((val) => val.attribute.id === 'AT_IconType')
          ?.values.some((val) => val.valueId === 'white BG vitorange'),
      )
      ?.target.values?.find(
        (val) => val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_400',
      )?.simpleValue;

    let backLink;
    if (path && path.length > 1) {
      backLink = path[path.length - 2].link;
    }

    return {
      title: title,
      backLink: backLink,
      breadcrumbs: path,
      iconUrl: icon,
    };
  }
}
