import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { LocaleDropdownComponent } from './components/locale-dropdown/locale-dropdown.component';

const material = [MatIconModule, MatMenuModule, MatButtonModule];

@NgModule({
  declarations: [LocaleDropdownComponent],
  exports: [LocaleDropdownComponent],
  imports: [
    FlexLayoutModule,
    CommonModule,
    TranslateModule.forChild(),
    ...material,
  ],
})
export class FooterModule {}
