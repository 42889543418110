import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PimGraphqlModule } from '../pim-graphql/pim-graphql.module';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { ClassificationComponent } from './components/overview-classification/classification.component';
import { PackageTypeComponent } from './components/overview-packagetype/package-type.component';
import { ProductComponent } from './components/overview-product/product.component';
import { ProductTypeComponent } from './components/overview-producttype/product-type.component';
import { OverviewRoutingModule } from './overview-routing.module';

const material = [MatIconModule, MatButtonModule, MatCardModule];

@NgModule({
  declarations: [
    ClassificationComponent,
    ProductComponent,
    ProductTypeComponent,
    PackageTypeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    OverviewRoutingModule,
    PimGraphqlModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    FlexLayoutModule,
    PipesModule,
    ...material,
  ],
})
export class OverviewModule {}
