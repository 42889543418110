import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { HeaderData } from '../../pim-graphql/views/shared-view.model';

@Component({
  selector: 'app-shared-header',
  templateUrl: './shared-header.component.html',
  styleUrls: ['./shared-header.component.scss'],
})
export class SharedHeaderComponent {
  @Input() headerData?: HeaderData;
  @Input() loading = true;
  @Input() startPage = false;

  constructor(private location: Location) {}

  backNav() {
    this.location.back();
  }
}
