<ng-container *ngIf="!loading; else skeleton">
  <mat-card *ngIf="checkHide()">
    <mat-card-title fxLayout="row wrap" fxLayoutAlign="space-between center">
      <span>
        {{ "detail.product_images.title" | translate }}
      </span>
      <mat-form-field
        *ngIf="imageGroups.length > 0"
        class="group-select"
        appearance="fill"
      >
        <mat-label>{{
          "detail.product_images.imagegroups" | translate
        }}</mat-label>
        <mat-select
          (selectionChange)="onSelectionChange($event.value)"
          [(value)]="selectedGroup"
        >
          <mat-option *ngFor="let group of imageGroups" [value]="group">
            {{ group.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-title>
    <mat-tab-group [(selectedIndex)]="selectedImage">
      <mat-tab
        *ngFor="let image of selectedGroup?.images; let i = index"
        label="{{ image.title }}"
      >
        <div
          class="tab-content"
          fxLayoutAlign="center"
          (click)="this.fullscreenView = true"
        >
          <img
            [fxHide]="!image.loaded"
            [src]="image.link"
            class="image"
            (load)="image.loaded = true"
          />
          <ngx-skeleton-loader
            *ngIf="!image.loaded"
            fxFlex
            [theme]="{
              height: '300px',
              'border-radius': '0',
              'margin-bottom': '0'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
  <app-image-fullscreen
    *ngIf="fullscreenView && !!selectedGroup"
    (fSClose)="fullscreenView = false"
    (fSPrev)="prev()"
    (fSNext)="next()"
    [image]="selectedGroup.images[selectedImage]"
    [enableControls]="selectedGroup.images.length > 1"
  ></app-image-fullscreen>
</ng-container>

<ng-template #skeleton>
  <ngx-skeleton-loader
    [theme]="{ height: '300px', width: '100%' }"
  ></ngx-skeleton-loader>
</ng-template>
