<ng-container *ngIf="!loading; else skeleton">
  <mat-card
    *ngIf="
      this.relations &&
      (this.relations.accessoryRel ||
        this.relations.packetsRel ||
        this.relations.packageContentRel ||
        this.relations.componentsRel.length > 0 ||
        this.relations.alternativesRel)
    "
  >
    <mat-card-title>{{
      "detail.product_relations.header" | translate
    }}</mat-card-title>
    <mat-tab-group dynamicHeight>
      <!--Components-->
      <mat-tab
        *ngFor="let componentsData of relations.componentsRel"
        label="{{ componentsData.title | translate }}"
      >
        <app-relations-table
          [relations]="componentsData.components"
        ></app-relations-table>
      </mat-tab>
      <!--PackageContent-->
      <mat-tab
        *ngIf="relations.packageContentRel"
        label="{{ relations.packageContentRel.title }}"
      >
        <app-relations-table
          [relations]="relations.packageContentRel.content"
        ></app-relations-table>
      </mat-tab>
      <!--Accessories-->
      <mat-tab
        *ngIf="relations.accessoryRel"
        label="{{ relations.accessoryRel.title }}"
      >
        <app-relations-group-table
          [relationGroups]="relations.accessoryRel.accessoryGroups"
        ></app-relations-group-table>
      </mat-tab>
      <!--Services-->
      <mat-tab
        *ngIf="relations.servicesRel"
        label="{{ relations.servicesRel.title }}"
      >
        <app-relations-table
          [relations]="relations.servicesRel.services"
        ></app-relations-table>
      </mat-tab>
      <!--DigitalServices-->
      <mat-tab
        *ngIf="relations.digitalServicesRel"
        label="{{ relations.digitalServicesRel.title }}"
      >
        <app-relations-table
          [relations]="relations.digitalServicesRel.digitalServices"
        ></app-relations-table>
      </mat-tab>
      <!--Packets-->
      <mat-tab
        label="{{ 'detail.product_relations.packets_header' | translate }}"
        *ngIf="relations.packetsRel"
      >
        <app-relations-table
          [relations]="relations.packetsRel.packets"
        ></app-relations-table>
      </mat-tab>
      <!--AlternativeProducts-->
      <mat-tab
        *ngIf="relations.alternativesRel"
        label="{{ relations.alternativesRel.title }}"
      >
        <app-relations-table
          [relations]="relations.alternativesRel.alternatives"
        ></app-relations-table>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</ng-container>

<ng-template #skeleton>
  <ngx-skeleton-loader
    [theme]="{ height: '300px', width: '100%' }"
  ></ngx-skeleton-loader>
</ng-template>
