import { Component, Input } from '@angular/core';
import { PathElement } from '../../../pim-graphql/views/shared-view.model';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  @Input() breadcrumbs?: PathElement[];
  @Input() loading = true;
}
