import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// eslint-disable-next-line camelcase
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, share, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

// Declare interface for Google Analytics 4
declare global {
  interface Window {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    dataLayer: any;
  }
}

interface DecodedCsrfToken {
  identityType: string;
  'x-uuid': string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public csrfToken$ = new BehaviorSubject<string | undefined>(undefined);
  private fetchNewCsrfToken$: Observable<{ token: string }> = new Observable(
    undefined,
  );

  private httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  public getAuthorizedIdentityTypes$(): Observable<boolean> {
    const allowedIdentityTypes = ['viint', 'viacc', 'viext'];

    return this.csrfToken$.pipe(
      filter(
        (csrfToken) =>
          csrfToken !== undefined && this.isCsrfTokenValid(csrfToken),
      ),
      map((csrfToken) => {
        const decodedCsrfToken = jwtDecode<DecodedCsrfToken>(csrfToken!);
        const identityType = decodedCsrfToken.identityType;
        const userId = decodedCsrfToken['x-uuid'];

        // Push data to window.dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'user_profile_loaded',
          identityType: identityType,
          userId: userId,
          userProfileLoaded: true,
        });

        // Return boolean indicating whether the identityType is allowed
        return (
          identityType !== undefined &&
          allowedIdentityTypes.includes(identityType.toLowerCase())
        );
      }),
    );
  }

  init() {
    this.fetchNewCsrfToken$ = this.getCsrfTokenRequest().pipe(
      tap({
        next: ({ token }) => {
          this.csrfToken$.next(token);
        },
        error: () => this.redirectToLoginScreen(),
      }),
      share(),
    );

    const token = this.getCsrfTokenFromUrl();

    if (token && this.isCsrfTokenValid(token)) {
      this.csrfToken$.next(token);
    } else {
      this.fetchNewCsrfToken$.subscribe();
    }
  }

  fetchNewCsrfToken() {
    return this.fetchNewCsrfToken$;
  }

  public getCsrfTokenRequest() {
    return this.httpClient.post<{ token: string }>(
      `${environment.auth.baseUrl}/auth/v1/saml/csrf`,
      {
        appId: environment.auth.appId,
      },
      {
        withCredentials: true,
      },
    );
  }

  private getCsrfTokenFromUrl(): string | void {
    const hash = window.location.hash;
    if (hash?.startsWith('#token=')) {
      const value = new HttpParams({ fromString: hash }).get('#token');
      window.location.hash = ''; // remove token form url
      return value || undefined;
    }
  }

  private redirectToLoginScreen() {
    window.location.href = `${environment.auth.baseUrl}/saml/sso/request?appId=${
      environment.auth.appId
    }&redirectUrl=${encodeURIComponent(window.location.href)}`;
  }

  private isCsrfTokenValid(token: string): boolean {
    try {
      return !this.isCSRFTokenExpired(token);
    } catch (_) {
      return false;
    }
  }

  isCSRFTokenExpired(token: string): boolean {
    const date = this.getTokenExpirationDate(token);
    if (!date) {
      return false;
    }
    return date.valueOf() <= new Date().valueOf();
  }

  getTokenExpirationDate(token: string): Date | null {
    const decoded = <{ exp?: number }>jwtDecode(token);

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }
}
