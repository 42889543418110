<app-shared-header
  [headerData]="product?.headerData"
  [loading]="loading"
></app-shared-header>
<ng-container *ngIf="!loading; else skeleton">
  <div
    fxLayout="row wrap"
    fxLayoutGap="16px 16px grid"
    fxLayout.lt-sm="column"
    fxLayoutGap.lt-sm="0 16px grid"
  >
    <app-shared-card
      *ngFor="let productType of product?.productTypes"
      fxFlex.gt-xs="50"
      fxFlex.gt-sm="33.33"
      [data]="productType"
    >
    </app-shared-card>
  </div>
</ng-container>
<ng-template #skeleton>
  <div
    fxLayout="row wrap"
    fxLayoutGap="16px 16px grid"
    fxLayout.lt-sm="column"
    fxLayoutGap.lt-sm="0 16px grid"
  >
    <ngx-skeleton-loader
      *ngFor="let i of [].constructor(5)"
      fxFlex.gt-xs="50"
      fxFlex.gt-sm="33.33"
      [theme]="{ height: '5em', 'margin-bottom': '0' }"
    ></ngx-skeleton-loader>
  </div>
</ng-template>
