import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Product, ValueContainer } from '../../../pim-graphql.model';
import { PimGraphqlService } from '../../../pim-graphql.service';
import { RelProduct } from '../../shared-view.model';
import { SharedViewService } from '../../shared-view.service';
import {
  AccessoriesData,
  AlternativesData,
  ComponentsData,
  DigitalServicesData,
  PackageContentData,
  PacketsData,
  Relations,
  RelGroup,
  ServicesData,
} from './detail-relations.model';
import { PimRoutePipe } from 'src/app/modules/pipes/pim-route.pipe';
import { ValidityDateService } from 'src/app/services/validity-date.service';

@Injectable({
  providedIn: 'root',
})
export class DetailRelationsService {
  constructor(
    private pim: PimGraphqlService,
    private validityService: ValidityDateService,
    private sharedViewService: SharedViewService,
    private routePipe: PimRoutePipe,
  ) {}

  getDetailRelations(id: string): Observable<Relations> {
    return this.pim.getProductRelationsById(id).pipe(
      map((product) => {
        return {
          packageContentRel: this.getPackageContent(product),
          accessoryRel: this.getAccessories(product),
          servicesRel: this.getServices(product),
          digitalServicesRel: this.getDigitalServices(product),
          packetsRel: this.getPackets(product),
          componentsRel: this.getComponents(product),
          alternativesRel: this.getAlternatives(product),
        };
      }),
    );
  }

  private getPackageContent(product: Product): PackageContentData | undefined {
    const contentRef = product.referencesByReferenceType?.find(
      (ref) => ref.referenceType.id === 'REF_Packet',
    );

    //Shallow copy to not alter Apollo cache
    const sortedEntries = this.sharedViewService.sortByMetadataAttribute(
      'AT_PosId',
      [...(contentRef?.referenceEntries || [])],
    );

    const content = sortedEntries?.map((set) => {
      return this.getRelProduct(set.target, set.values, true);
    });

    if (contentRef && content && content.length > 0) {
      return {
        title: contentRef.referenceType.title,
        content: content,
      };
    }
  }

  private getComponents(product: Product): ComponentsData[] {
    return product.children.pageElements.reduce<ComponentsData[]>(
      (output, plv) => {
        let refType: string;
        if (plv.objectType.id === 'OT_SalesPartsList') {
          refType = 'REF_SalesPartsList';
        } else if (plv.objectType.id === 'OT_SalesPartsListComponentGroup') {
          refType = 'REF_SalesPartsListComponentGroup';
        } else {
          return output;
        }

        const variantMembers = plv.referencesByReferenceType?.find(
          (ref) => ref.referenceType.id === refType,
        );

        const currentValidityDate = this.validityService
          ?.getValidityDate()
          ?.getTime();

        const filteredEntries = variantMembers?.referenceEntries.filter(
          (entry) => {
            if (!currentValidityDate) {
              return true;
            }
            const from = entry.values.find(
              (val) => val.attribute.id === 'AT_StartDate',
            )?.values?.[0]?.value;
            const to = entry.values.find(
              (val) => val.attribute.id === 'AT_EndDate',
            )?.values?.[0]?.value;

            if (!from || !to) {
              return false;
            }
            const fromDate = new Date(from).getTime();
            const toDate = new Date(to).getTime();

            return (
              fromDate <= currentValidityDate && currentValidityDate < toDate
            );
          },
        );

        const sortedEntries = this.sharedViewService.sortByMetadataAttribute(
          'AT_PosId',
          filteredEntries,
        );

        const components = sortedEntries?.map((comp) => {
          return this.getRelProduct(comp.target, comp.values, true);
        });

        if (variantMembers && components && components.length > 0) {
          output.push({
            title:
              plv.objectType.id === 'OT_SalesPartsListComponentGroup'
                ? 'detail.product_relations.component_part_list_variant'
                : 'detail.product_relations.part_list_variant',
            components: components,
          });
        }

        return output;
      },
      [],
    );
  }

  private getAccessories(product: Product): AccessoriesData | undefined {
    const accRef = product.referencesByReferenceType?.find((ref) => {
      return 'REF_Accessories' === ref.referenceType.id;
    });

    const accessoryGroups: RelGroup[] = [];
    const ungroupedAccessories: RelGroup = {
      groupTitle: {
        title: 'detail.product_attributes.ungrouped_header',
        translatable: true,
      },
      relations: [],
    };

    accRef?.referenceEntries.forEach((accessory) => {
      const groupName = accessory.target.values?.find(
        (val) => val.attribute.id === 'AT_SAP_V_GRUPPENMERKMAL2',
      )?.simpleValue;

      const relProduct = this.getRelProduct(
        accessory.target,
        accessory.values,
        true,
      );

      if (!groupName) {
        ungroupedAccessories.relations.push(relProduct);
        return;
      }

      const existingGroup = accessoryGroups.find(
        (group) => group.groupTitle.title === groupName,
      );

      if (existingGroup) {
        existingGroup.relations.push(relProduct);
      } else {
        accessoryGroups.push({
          groupTitle: {
            title: groupName,
            translatable: false,
          },
          relations: [relProduct],
        });
      }
    });

    if (ungroupedAccessories.relations.length > 0) {
      accessoryGroups.push(ungroupedAccessories);
    }

    if (accRef && accessoryGroups.length > 0) {
      return {
        title: accRef.referenceType.title,
        accessoryGroups: accessoryGroups,
      };
    }
  }

  private getServices(product: Product): ServicesData | undefined {
    const servicesRef = product.referencesByReferenceType?.find(
      (ref) => ref.referenceType.id === 'REF_Services',
    );

    //Shallow copy to not alter Apollo cache
    const sortedEntries = this.sharedViewService.sortByMetadataAttribute(
      'AT_PosId',
      [...(servicesRef?.referenceEntries || [])],
    );

    const services = sortedEntries?.map((service) => {
      return this.getRelProduct(service.target, service.values, true);
    });

    if (servicesRef && services && services.length > 0) {
      return {
        title: servicesRef.referenceType.title,
        services: services,
      };
    }
  }

  private getDigitalServices(
    product: Product,
  ): DigitalServicesData | undefined {
    const digitalServicesRef = product.referencesByReferenceType?.find(
      (ref) => ref.referenceType.id === 'REF_DigitalService',
    );

    const digitalServices = digitalServicesRef?.referenceEntries?.map(
      (digitalService) => {
        return this.getRelProduct(
          digitalService.target,
          digitalService.values,
          true,
        );
      },
    );

    if (digitalServicesRef && digitalServices && digitalServices.length > 0) {
      return {
        title: digitalServicesRef.referenceType.title,
        digitalServices: digitalServices,
      };
    }
  }

  private getPackets(product: Product): PacketsData | undefined {
    const packetsRef = product.incomingReferencesByReferenceType?.find(
      (ref) => ref.referenceType.id === 'REF_Packet',
    );

    const packets = packetsRef?.referenceEntries.pageElements.map((packet) => {
      return this.getRelProduct(packet.source);
    });

    if (packetsRef && packets && packets.length > 0) {
      return {
        title: packetsRef.referenceType.title,
        packets: packets,
      };
    }
  }

  private getAlternatives(product: Product): AlternativesData | undefined {
    const alternativeProductsRef = product.referencesByReferenceType?.find(
      (ref) => ref.referenceType.id === 'REF_AlternativeProducts',
    );

    const alternativeProducts = alternativeProductsRef?.referenceEntries?.map(
      (alternativeProduct) => {
        return this.getRelProduct(
          alternativeProduct.target,
          alternativeProduct.values,
          true,
        );
      },
    );

    if (
      alternativeProductsRef &&
      alternativeProducts &&
      alternativeProducts.length > 0
    ) {
      return {
        title: alternativeProductsRef.referenceType.title,
        alternatives: alternativeProducts,
      };
    }
  }

  private getRelProduct(
    product: Product,
    values?: ValueContainer[],
    attachLink?: boolean,
  ): RelProduct {
    const amount = values?.find((val) => val.attribute.id === 'AT_Amount');
    return {
      posNr: values?.find((val) => val.attribute.id === 'AT_PosId')?.values?.[0]
        ?.value,
      title: product.title,
      text: product.values?.find((val) => val.attribute.id === 'AT_MAKTX')
        ?.simpleValue,
      imageUrl: product.primaryImage?.values?.find(
        (val) => val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_400',
      )?.simpleValue,
      amount: {
        amount: amount?.values?.[0]?.value,
        unit: amount?.values?.[0]?.unit?.title,
        validationType: amount?.attribute.validation?.name,
      },
      link: attachLink
        ? this.routePipe.transform(product.id, product.objectType.id)
        : undefined,
    };
  }
}
