import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { filter, Subscription, switchMap, tap } from 'rxjs';
import { LegendData } from 'src/app/modules/pim-graphql/views/detail-view/detail-legend-texts-view/detail-legend-texts.model';
import { DetailLegendTextsService } from 'src/app/modules/pim-graphql/views/detail-view/detail-legend-texts-view/detail-legend-texts.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-legend-texts',
  templateUrl: './legend-texts.component.html',
  styleUrls: ['./legend-texts.component.scss'],
})
export class LegendTextsComponent implements OnChanges, OnDestroy {
  @Input() productId?: string;
  legendData?: LegendData;
  loading = true;
  private refresh$?: Subscription;
  constructor(
    private detailLTService: DetailLegendTextsService,
    private refreshService: RefreshService,
  ) {}

  ngOnChanges(): void {
    this.refresh$?.unsubscribe();
    this.refresh$ = this.refreshService.refreshData$
      .pipe(
        filter(() => !!this.productId),
        tap(() => (this.loading = true)),
        switchMap(() =>
          this.detailLTService.getDetailLegendTexts(this.productId ?? ''),
        ),
      )
      .subscribe((legend) => {
        this.loading = false;
        this.legendData = legend;
      });
  }

  ngOnDestroy(): void {
    this.refresh$?.unsubscribe();
  }

  checkHide(): boolean | undefined {
    return this.legendData && this.legendData.legends.length > 0;
  }
}
