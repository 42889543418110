import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PimRoutePipe } from '../pipes/pim-route.pipe';
import { StiboPipe } from '../pipes/stibo.pipe';
import { XMLTagPipe } from '../pipes/xml-tag.pipe';
import { PimGraphqlService } from './pim-graphql.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule],
  providers: [PimGraphqlService, StiboPipe, XMLTagPipe, PimRoutePipe],
})
export class PimGraphqlModule {}
