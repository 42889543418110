import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription, switchMap } from 'rxjs';
import { PackageTypeData } from 'src/app/modules/pim-graphql/views/package-type-view/package-type.model';
import { PackageTypeService } from 'src/app/modules/pim-graphql/views/package-type-view/package-type.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-package-type',
  templateUrl: './package-type.component.html',
  styleUrls: ['./package-type.component.scss'],
})
export class PackageTypeComponent implements OnInit, OnDestroy {
  packageType?: PackageTypeData;
  loading = true;
  private paramSubscription?: Subscription;

  constructor(
    private packageTypeService: PackageTypeService,
    private route: ActivatedRoute,
    private refreshService: RefreshService,
  ) {}

  ngOnInit(): void {
    this.paramSubscription = combineLatest([
      this.route.params,
      this.refreshService.refreshData$,
    ])
      .pipe(
        switchMap((values) => {
          const id: string = values[0]['packageTypeId'];
          this.packageType = undefined;
          this.loading = true;
          return this.packageTypeService.getPackageTypeData(id);
        }),
      )
      .subscribe((packageType) => {
        this.packageType = packageType;
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.paramSubscription?.unsubscribe();
  }
}
