<div class="vertical-scroll-wrapper">
  <table mat-table [dataSource]="relations" class="mat-elevation-z0">
    <ng-container matColumnDef="posnr">
      <th mat-header-cell *matHeaderCellDef>
        {{ "detail.product_relations.posnr" | translate }}
      </th>
      <td mat-cell *matCellDef="let relation">{{ relation.posNr }}</td>
    </ng-container>

    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef>
        {{ "detail.product_relations.image" | translate }}
      </th>
      <td mat-cell *matCellDef="let relation" class="thumbnail">
        <img *ngIf="!!relation.imageUrl" [src]="relation.imageUrl" />
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>
        {{ "detail.product_relations.title" | translate }}
      </th>
      <td mat-cell *matCellDef="let relation">{{ relation.title }}</td>
    </ng-container>

    <ng-container matColumnDef="text">
      <th mat-header-cell *matHeaderCellDef>
        {{ "detail.product_attributes.text" | translate }}
      </th>
      <td mat-cell *matCellDef="let relation">{{ relation.text }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>
        {{ "detail.product_relations.amount" | translate }}
      </th>
      <td mat-cell *matCellDef="let relation">
        {{ relation.amount.amount | decimal: relation.amount.validationType }}
        {{ relation.amount.unit }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.row-clickable]="row.link"
      [routerLink]="row.link ? row.link : []"
    ></tr>
  </table>
</div>
