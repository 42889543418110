import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PimRoutePipe } from './pim-route.pipe';
import { StiboDecimalPipe } from './stibo-decimal.pipe';
import { StiboPipe } from './stibo.pipe';
import { XMLTagPipe } from './xml-tag.pipe';

@NgModule({
  declarations: [StiboDecimalPipe, StiboPipe, XMLTagPipe, PimRoutePipe],
  imports: [CommonModule],
  exports: [StiboDecimalPipe, StiboPipe, XMLTagPipe, PimRoutePipe],
})
export class PipesModule {}
