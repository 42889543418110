import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  readonly refreshData$ = new BehaviorSubject<void>(undefined);

  public initRefresh() {
    this.refreshData$.next();
  }
}
