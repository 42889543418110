import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Product, ReferenceEntry } from '../../../pim-graphql.model';
import { PimGraphqlService } from '../../../pim-graphql.service';
import { Text } from '../../shared-view.model';
import { SharedViewService } from '../../shared-view.service';
import {
  CompositeImageText,
  CompositeMultiValText,
  CompositeText,
  ImageText,
  MultiValText,
  Texts,
} from './detail-texts.model';
import { StiboPipe } from 'src/app/modules/pipes/stibo.pipe';

@Injectable({
  providedIn: 'root',
})
export class DetailTextsService {
  constructor(
    private pim: PimGraphqlService,
    private stiboTags: StiboPipe,
    private sharedViewService: SharedViewService,
  ) {}

  getDetailTexts(id: string): Observable<Texts> {
    return this.pim.getProductTextsById(id).pipe(
      map((product) => {
        return {
          advantageTexts: this.getAdvantageTexts(product),
          imageTexts: this.getImageTexts(product),
          marketingTexts: this.getMarketingTexts(product),
          texts: this.getTexts(product),
        };
      }),
    );
  }

  private getTexts(product: Product): Text[] {
    const texts: Text[] = [];

    let text = product.values?.find(
      (val) => val.attribute.id === 'AT_OfferingText',
    );
    if (text && !!text.simpleValue) {
      texts.push({
        title: text?.attribute.title,
        content: this.stiboTags.transform(text?.simpleValue),
      });
    }
    text = product.values?.find(
      (val) => val.attribute.id === 'AT_OfferingTextLong',
    );
    if (text && !!text.simpleValue) {
      texts.push({
        title: text?.attribute.title,
        content: this.stiboTags.transform(text?.simpleValue),
      });
    }
    return texts;
  }

  private getAdvantageTexts(product: Product): CompositeText[] | undefined {
    const advantageEntries = product.referencesByReferenceType?.filter(
      (ref) => {
        return ref.referenceType.id.startsWith('REF_BenefitText_');
      },
    );

    const advantageTexts = advantageEntries?.reduce<CompositeText[]>(
      (texts, adv) => {
        //Shallow copy to not alter Apollo cache
        const entries = [...adv.referenceEntries];
        const sortedEntries = this.sharedViewService.sortByMetadataAttribute(
          'AT_PosNo',
          entries,
        );

        const textModules = sortedEntries?.map<Text>((entry) => {
          const attr = entry.target.values?.find(
            (val) => val.attribute.id === 'AT_TB_Content',
          );
          return {
            title: entry.target.title,
            content: this.stiboTags.transform(attr?.simpleValue),
          };
        });

        if (textModules && textModules.length > 0) {
          texts.push({
            title: adv.referenceType.title,
            textModules: textModules,
          });
        }

        return texts;
      },
      [],
    );

    if (advantageTexts && advantageTexts.length > 0) {
      return advantageTexts;
    }
  }

  private getImageTexts(product: Product): CompositeImageText[] | undefined {
    const texts = product.referencesByReferenceType?.filter((ref) => {
      return [
        'REF_TechnologyDescription',
        'REF_FeatureDescription',
        'REF_BenefitBranding',
      ].includes(ref.referenceType.id);
    });

    const imageTexts = texts?.reduce<CompositeImageText[]>((texts, ref) => {
      //Shallow copy to not alter Apollo cache
      const entries = [...ref.referenceEntries];
      const sortedEntries = this.sharedViewService.sortByMetadataAttribute(
        'AT_PosNo',
        entries,
      );

      const textModules = sortedEntries?.map<ImageText>((entry) => {
        const title = entry.target.values?.find(
          (val) => val.attribute.id === 'AT_TB_Headline',
        );
        const content = entry.target.values?.find(
          (val) => val.attribute.id === 'AT_TB_Content',
        );
        const imageUrls = this.getImageTextsUrls(entry);

        return {
          title: this.stiboTags.transform(title?.simpleValue),
          content: this.stiboTags.transform(content?.simpleValue),
          imageUrls: imageUrls,
        };
      });

      if (textModules && textModules.length > 0) {
        texts.push({
          title: ref.referenceType.title,
          textModules: textModules,
        });
      }
      return texts;
    }, []);

    if (imageTexts && imageTexts.length > 0) {
      return imageTexts;
    }
  }

  private getImageTextsUrls(entry: ReferenceEntry): string[] | undefined {
    return entry.target.referencesByReferenceType
      ?.find((ref) => {
        return ref.referenceType.id === 'REF_Branding_Icon';
      })
      ?.referenceEntries?.reduce<string[]>((imgs, img) => {
        const imgLink = img.target.values?.find(
          (val) => val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_400',
        )?.simpleValue;

        const imgKontrast = img.target.values
          ?.find((val) => val.attribute.id === 'AT_IconType')
          ?.values.some((val) => val.valueId === 'vitorange');
        if (imgKontrast && imgLink) {
          imgs.push(imgLink);
        }
        return imgs;
      }, []);
  }

  private getMarketingTexts(
    product: Product,
  ): CompositeMultiValText | undefined {
    const attributeGroup = product.valuesByDataTypeGroup?.find(
      (dtg) => dtg.dataTypeGroup.id === 'ATG_SellingAttributes_Texts',
    );

    const texts: MultiValText[] = [];

    //Positionierungstext
    const values = [
      attributeGroup?.values.find(
        (val) => val.attribute.id === 'AT_PositioningtextShort',
      ),
    ];
    //Produktüberschrift
    values.push(
      attributeGroup?.values.find(
        (val) => val.attribute.id === 'AT_ProductHeadline',
      ),
    );
    //Produktbeschreibung
    values.push(
      attributeGroup?.values.find(
        (val) => val.attribute.id === 'AT_ProductDescription',
      ),
    );
    //Produktmerkmale Marketing
    values.push(
      attributeGroup?.values.find(
        (val) => val.attribute.id === 'AT_ProductFeaturesMarketing',
      ),
    );

    values.forEach((value) => {
      if (value && value.values.length > 0 && value.values[0].value) {
        texts.push({
          title: value.attribute.title,
          content: value.values.reduce<string[]>((mapped, val) => {
            const transformed = this.stiboTags.transform(val.value);
            if (transformed) {
              mapped.push(transformed);
            }
            return mapped;
          }, []),
        });
      }
    });

    if (texts.length > 0) {
      return {
        title: attributeGroup?.dataTypeGroup.title,
        textModules: texts,
      };
    }
  }
}
