import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Product, ReferenceContainer } from '../../../pim-graphql.model';
import { PimGraphqlService } from '../../../pim-graphql.service';
import { DetailImage, DetailImageGroup } from './detail-image.model';

@Injectable({
  providedIn: 'root',
})
export class DetailImageService {
  constructor(private pim: PimGraphqlService) {}

  getDetailImages(id: string): Observable<DetailImageGroup[]> {
    return this.pim.getProductImagesById(id).pipe(
      map((product) => {
        return this.getImages(product);
      }),
    );
  }

  private getImages(product: Product): DetailImageGroup[] {
    return (
      product.referencesByReferenceType?.reduce<DetailImageGroup[]>(
        (detailImgs, imgRef) => {
          const images = this.mapDetailImages(imgRef);
          if (images.length > 0) {
            const imageGroup = {
              title: imgRef.referenceType.title,
              images: images,
            };
            detailImgs.push(imageGroup);
          }
          return detailImgs;
        },
        [],
      ) ?? []
    );
  }

  private mapDetailImages(imgRef: ReferenceContainer): DetailImage[] {
    return imgRef.referenceEntries.reduce<DetailImage[]>(
      (detailImgs, imgEntry) => {
        const title = imgEntry.target.values?.find(
          (val) => val.attribute.id === 'AT_ProductImage_subtype',
        )?.simpleValue;
        const imageLink = imgEntry.target.values?.find(
          (val) => val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_1200',
        )?.simpleValue;
        if (imageLink) {
          detailImgs.push({
            title: title ?? imgEntry.target.title,
            id: imgEntry.target.id,
            link: imageLink,
            loaded: false,
          });
        }
        return detailImgs;
      },
      [],
    );
  }
}
