import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClassificationComponent } from './components/overview-classification/classification.component';
import { PackageTypeComponent } from './components/overview-packagetype/package-type.component';
import { ProductComponent } from './components/overview-product/product.component';
import { ProductTypeComponent } from './components/overview-producttype/product-type.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: 'classification/:classificationId',
    component: ClassificationComponent,
  },
  {
    path: 'product/:productId',
    component: ProductComponent,
  },
  {
    path: 'producttype/:productTypeId',
    component: ProductTypeComponent,
  },
  {
    path: 'packagetype/:packageTypeId',
    component: PackageTypeComponent,
  },
  {
    path: '**',
    redirectTo: `classification/${environment.pim.topClassification}`,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OverviewRoutingModule {}
