<div class="search-bar">
  <div
    class="product-search-input"
    [class.product-search-input-focused]="inputFocused"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-icon (click)="searchBarInput.focus()">search</mat-icon>
    <input
      fxFlex
      type="text"
      #searchBarInput
      [matAutocomplete]="auto"
      [formControl]="searchInputControl"
      (focusin)="focusInCheck()"
      (focusout)="focusOutCheck(searchBarInput.value)"
      placeholder="{{ 'nav_header.search' | translate }}"
    />

    <ng-container *ngIf="inputContainsText">
      <mat-icon
        *ngIf="!loading; else spinner"
        (click)="onCloseClick(searchBarInput)"
        >close</mat-icon
      >
      <ng-template #spinner>
        <mat-spinner class="autocomplete-spinner" [diameter]="24"></mat-spinner>
      </ng-template>
    </ng-container>

    <mat-autocomplete
      #auto="matAutocomplete"
      class="autocomplete"
      (optionSelected)="goToResult($event.option.value)"
      [displayWith]="displayResult"
    >
      <mat-optgroup
        *ngFor="let group of suggestions$ | async"
        [label]="group.title"
      >
        <mat-option *ngFor="let result of group.results" [value]="result">
          {{ result.title }}
          <span *ngIf="!!result.description"> - {{ result.description }}</span>
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </div>
</div>
