<div class="vertical-scroll-wrapper">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
    <!-- Relations -->
    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef>
        {{ "detail.product_relations.image" | translate }}
      </th>
      <td mat-cell *matCellDef="let relation" class="thumbnail">
        <img *ngIf="!!relation.imageUrl" [src]="relation.imageUrl" />
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>
        {{ "detail.product_relations.title" | translate }}
      </th>
      <td mat-cell *matCellDef="let relation">{{ relation.title }}</td>
    </ng-container>

    <ng-container matColumnDef="text">
      <th mat-header-cell *matHeaderCellDef>
        {{ "detail.product_attributes.text" | translate }}
      </th>
      <td mat-cell *matCellDef="let relation">{{ relation.text }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.row-clickable]="row.link"
      [routerLink]="row.link ? row.link : []"
    ></tr>

    <!-- Group header -->
    <ng-container matColumnDef="groupHeader">
      <td mat-cell colspan="999" *matCellDef="let groupHeader">
        <ng-container *ngIf="!groupHeader.translatable; else translate">
          <strong>{{ groupHeader.title }}</strong>
        </ng-container>
        <ng-template #translate>
          <strong>{{ groupHeader.title | translate }}</strong>
        </ng-template>
      </td>
    </ng-container>

    <tr
      mat-row
      *matRowDef="let row; columns: ['groupHeader']; when: isGroupHeader"
    ></tr>
  </table>
</div>
