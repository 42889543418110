<ng-container *ngIf="!loading; else skeleton">
  <mat-card *ngIf="checkHide()">
    <mat-card-title>{{ examplesData?.title }}</mat-card-title>
    <mat-tab-group dynamicHeight>
      <mat-tab
        *ngFor="let example of examplesData?.examples"
        label="{{ example.title }}"
      >
        <div fxLayout="column">
          <div
            class="system"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutGap="16px"
          >
            <img
              *ngIf="example.systemImage"
              class="system-image"
              [src]="example.systemImage"
            />
            <div>
              <b>{{ example.text?.title }}</b>
              <div [innerHtml]="example.text?.content"></div>
            </div>
          </div>
          <app-relations-table
            *ngIf="example.accessories && example.accessories.length > 0"
            [relations]="example.accessories"
          ></app-relations-table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</ng-container>

<ng-template #skeleton>
  <ngx-skeleton-loader
    [theme]="{ height: '300px', width: '100%' }"
  ></ngx-skeleton-loader>
</ng-template>
